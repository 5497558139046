import React from "react";
import { LOG_DIRECTIONS, QUICKBOOKS_DESKTOP_INTEGRATION_ID } from "../../../../../../constants";
import { MergeMarkAvatar } from "../../../../../shared-components/MergeAvatars";
import Divider from "../../../../../shared-components/Divider";
import {
  isScraperLogEntry,
  isWebhookLogEntry,
  LogType,
  ScraperLogEntry,
} from "../../../../IntegrationsManagementEntities";
import { LogSidePanelBodySection } from "./LogSidePanelBodySection";
import { LogSidePanelBodySectionHeader } from "./LogSidePanelBodySectionHeader";
import { CollapsableSection, FullWidthCard } from "./LogSidePanelBody";
import { ScraperLogSidePanelRequestSection } from "../../ScraperLogEntrySidePanel";

type Props = {
  logEntry: LogType;
  organizationBodyImage: JSX.Element;
  integrationImage: JSX.Element;
};

export const LogSidePanelRequestSection = ({
  logEntry,
  organizationBodyImage,
  integrationImage,
}: Props) => {
  const isOutbound = logEntry.direction === LOG_DIRECTIONS.LOG_OUTBOUND;
  const isWebhookLog = isWebhookLogEntry(logEntry);
  const isWebConnectorLog =
    logEntry.linked_account.integration.id == QUICKBOOKS_DESKTOP_INTEGRATION_ID;
  const isScraperLog = isScraperLogEntry(logEntry);

  const requestSubtitle = isOutbound ? "Merge" : logEntry.linked_account.end_user.organization_name;
  const requestImage = isOutbound ? (
    <MergeMarkAvatar />
  ) : isWebhookLog || isWebConnectorLog ? (
    integrationImage
  ) : (
    organizationBodyImage
  );

  const shouldShowRequestHeaders =
    !isScraperLog && logEntry.request_headers && Object.keys(logEntry.request_headers).length > 0;
  const shouldShowRequestQueryParams =
    !isScraperLog &&
    logEntry.request_query_params &&
    Object.keys(logEntry.request_query_params).length > 0;
  const shouldShowRequestBody =
    !isScraperLog &&
    logEntry.request_body &&
    logEntry.request_body != "{}" &&
    Object.keys(logEntry.request_body).length > 0;

  return (
    <FullWidthCard>
      <LogSidePanelBodySectionHeader
        title="Request"
        subtitle={requestSubtitle}
        image={requestImage}
        request_method={logEntry.method}
      />

      <FullWidthCard.Body className="log-card-body">
        {isScraperLog ? (
          <CollapsableSection
            title="Request parameters"
            numberOfElements={undefined}
            children={<ScraperLogSidePanelRequestSection logEntry={logEntry as ScraperLogEntry} />}
          />
        ) : (
          <>
            {shouldShowRequestHeaders && (
              <LogSidePanelBodySection
                title="Headers"
                data={logEntry.request_headers}
                isCollapsedByDefault
              />
            )}
            {shouldShowRequestHeaders &&
              (shouldShowRequestQueryParams || shouldShowRequestBody) && <Divider noMargin />}
            {shouldShowRequestQueryParams && (
              <LogSidePanelBodySection
                title="Query parameters"
                data={logEntry.request_query_params}
              />
            )}
            {shouldShowRequestQueryParams && shouldShowRequestBody && <Divider noMargin />}
            {shouldShowRequestBody && (
              <LogSidePanelBodySection title="Body" data={logEntry.request_body} isBody />
            )}
          </>
        )}
      </FullWidthCard.Body>
    </FullWidthCard>
  );
};
