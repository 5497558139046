import React, { Dispatch, SetStateAction } from "react";
import {
  Button,
  ButtonVariant,
  Link,
  Toggle,
  Tooltip,
  Text,
} from "@merge-api/merge-javascript-shared";
import ConfigurationSettingsCard from "../link/helpers/ConfigurationSettingsCard";
import { Eye } from "lucide-react";

type ConfigurationSelectiveSyncSettingsPageProps = {
  setConditionPresetPreviewLinkDialogOpen: Dispatch<SetStateAction<boolean>>;
};

const ConfigurationSelectiveSyncSettingsPage = ({
  setConditionPresetPreviewLinkDialogOpen,
}: ConfigurationSelectiveSyncSettingsPageProps) => {
  return (
    <ConfigurationSettingsCard
      title="Show in Merge Link"
      rightHandContent={
        <>
          <Tooltip title="Selective Sync is disabled in Merge Link">
            <Button
              size="sm"
              variant={ButtonVariant.TertiaryWhite}
              fullWidth
              leftIcon={<Eye className="h-3, w-3"></Eye>}
              onClick={() => setConditionPresetPreviewLinkDialogOpen(true)}
              disabled
            >
              Preview
            </Button>
          </Tooltip>
        </>
      }
    >
      <div className="flex flex-row justify-between">
        <span>
          Show preset filters in Merge Link to allow your users to select what data is synced from
          third-party platforms
        </span>
        <Tooltip
          title={
            <Text variant="sm">
              <Link href="/billing" className="text-white underline">
                Upgrade your plan{" "}
              </Link>
              to configure Selective Sync in Link.
            </Text>
          }
        >
          <Toggle
            checked={false}
            disabled
            label={<span className="text-gray-600">Disabled</span>}
          />
        </Tooltip>
      </div>
    </ConfigurationSettingsCard>
  );
};

export default ConfigurationSelectiveSyncSettingsPage;
