import React from "react";
import clsx from "clsx";
import { Button, ButtonVariant, InputCombo, Text } from "@merge-api/merge-javascript-shared";
import { CommonModelSelect, FieldSelect, OperatorSelect, ValueTextField } from "./components";
import { ConditionPreset, ConditionPresetMeta } from "../../../../models/Entities";
import { Trash2 } from "lucide-react";
import SupportedIntegrationsTooltip from "./components/SupportedIntegrationsTooltip";
import { compact } from "lodash";
import UserConfiguredPlaceholder from "./components/UserConfiguredPlaceholder";
import { RELATIVE_DATE_CONDITION_OPERATORS } from "../constants";
import RelativeDatePicker from "../RelativeDatePicker/RelativeDatePicker";

type ConditionPresetInputComboProps = {
  className?: string;
  conditionPreset: Partial<ConditionPreset>;
  errorText: string | undefined;
  conditionPresetMeta: ConditionPresetMeta;
  conditionPresets: Partial<ConditionPreset>[];
  onConditionPresetChange: (conditionPreset: Partial<ConditionPreset>) => void;
  onConditionPresetDelete: () => void;
  isFirst: boolean;
  showGatedSelectiveSync: boolean;
  defaultDataEnabled?: boolean;
};

const ConditionPresetInputCombo = ({
  className,
  conditionPreset,
  errorText,
  conditionPresetMeta,
  conditionPresets,
  onConditionPresetChange,
  onConditionPresetDelete,
  isFirst,
  showGatedSelectiveSync,
  defaultDataEnabled,
}: ConditionPresetInputComboProps) => {
  // instead of disabling fields, we hide them, and replace them with empty space
  const optionalSelectFields = compact([
    conditionPreset.common_model && (
      <FieldSelect
        key="field"
        conditionPreset={conditionPreset}
        conditionPresetMeta={conditionPresetMeta}
        conditionPresets={conditionPresets}
        onConditionPresetChange={onConditionPresetChange}
        showGatedSelectiveSync={showGatedSelectiveSync}
      />
    ),
    conditionPreset.normalized_key_name && (
      <OperatorSelect
        key="operator"
        conditionPreset={conditionPreset}
        conditionPresetMeta={conditionPresetMeta}
        onConditionPresetChange={onConditionPresetChange}
        showGatedSelectiveSync={showGatedSelectiveSync}
      />
    ),
    conditionPreset.operator &&
      (RELATIVE_DATE_CONDITION_OPERATORS.includes(conditionPreset.operator) ? (
        <RelativeDatePicker
          key="relative-date-picker"
          onConditionPresetChange={onConditionPresetChange}
          conditionPreset={conditionPreset}
        />
      ) : (
        <ValueTextField
          key="value"
          conditionPreset={conditionPreset}
          conditionPresetMeta={conditionPresetMeta}
          onConditionPresetChange={onConditionPresetChange}
        />
      )),
  ]);

  return (
    <div className={clsx("flex flex-col", className)}>
      <div className="flex flex-row items-center">
        <Text className="min-w-[57px]">{isFirst ? "where" : "and"}</Text>
        <InputCombo
          fullWidth
          error={!!errorText}
          errorText={errorText}
          inputs={compact([
            <CommonModelSelect
              key="common-model"
              conditionPreset={conditionPreset}
              conditionPresetMeta={conditionPresetMeta}
              conditionPresets={conditionPresets}
              onConditionPresetChange={onConditionPresetChange}
              showGatedSelectiveSync={showGatedSelectiveSync}
            />,
            ...optionalSelectFields,

            // add blank space equal to number of hidden inputs
            optionalSelectFields.length < 3 && (
              <div className="flex" style={{ flex: 3 - optionalSelectFields.length }}>
                {conditionPreset?.is_end_user_configured && <UserConfiguredPlaceholder />}
              </div>
            ),
            !showGatedSelectiveSync && (
              <Button
                key="trash"
                size="sm"
                variant={ButtonVariant.IconShadowHidden}
                className="mr-1"
                onClick={onConditionPresetDelete}
              >
                <Trash2 size={16} />
              </Button>
            ),
          ])}
        />
      </div>
      <SupportedIntegrationsTooltip
        className="mt-1.5 ml-14 pl-1"
        conditionPresetMeta={conditionPresetMeta}
        conditionPreset={conditionPreset}
      />
    </div>
  );
};

export default ConditionPresetInputCombo;
