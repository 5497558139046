import React, { useEffect, useState } from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { APICategory } from "../../../models/Entities";
import { displayNameForAPICategory, lucideIconForAPICategory } from "../../../models/Helpers";
import {
  DOCS_API_REF_ASSOCIATIONS_PATH,
  DOCS_API_REF_ASSOCIATION_TYPES_PATH,
  DOCS_API_REF_CUSTOM_OBJECTS_PATH,
  DOCS_API_REF_CUSTOM_OBJECT_CLASSES_PATH,
  DOCS_CUSTOM_OBJECTS_PATH,
  openInNewTab,
} from "../../../router/RouterUtils";
import { SectionHeaderWrapper } from "../../shared-components/MergeLayouts";
import { BookOpen } from "lucide-react";
import {
  fetchCustomObjectsCommonModelsFromCategory,
  updateCustomObjectCommonModel,
} from "../../../api-client/categories/CommonModelTogglesAPIClient";
import CommonModel from "../../../models/CommonModel";
import { showErrorToast } from "../../shared-components/Toasts";
import {
  Button as MergeButton,
  Alert,
  Badge,
  Toggle,
  ButtonVariant,
} from "@merge-api/merge-javascript-shared";
import SkeletonLoader from "../../shared-components/SkeletonLoader";

/**
 * Add categories here if you want them to display as 'Coming soon'. If isActive is set to false, they will display the 'Coming soon' tag.
 * If isActive is set to True, users will be able to toggle Custom Object scopes for that feature.
 */

type CategoryIsActiveConfig = {
  isActive: boolean;
};

const CategoryDisplayConfig: Partial<Record<APICategory, CategoryIsActiveConfig>> = {
  [APICategory.crm]: {
    isActive: true,
  },
};

const ConfigurationSyncingCustomObjects = () => {
  /**
   * Add backend for Custom Objects and activate here. Using as a placeholder currently
   */
  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(false);
  const [commonModels, setCommonModels] = useState<CommonModel[]>([]);

  useEffect(() => {
    fetchCustomObjectsCommonModelsFromCategory().then((result) => {
      if (result.status === "success") {
        setDataHasLoaded(true);
        return setCommonModels(result.data);
      }
    });
  }, []);

  /**
   * Function that takes in a category and returns whether or not the Custom Objects Common Models are activated or not
   * It does this by checking if the model has enabled_model_actions and if the enabled_model_actions length is greater than 0
   * Scopes are 'disabled' when enabled_model_actions = [], and are enabled when they have their proper model action inside the array
   */

  function isCustomObjectsEnabledForCategory(category: string) {
    const filteredModels = commonModels.filter((model) => model.name.startsWith(category));
    return filteredModels.every(
      (model) => model.enabled_model_actions && model.enabled_model_actions.length > 0,
    );
  }

  const handleStatusToggle = (category: APICategory) => {
    updateCustomObjectCommonModel({
      category: category,
      isEnabled: isCustomObjectsEnabledForCategory(category),
    }).then((result) => {
      if (result.status === "success") {
        setCommonModels(result.data);
        return { status: "success", data: undefined };
      } else {
        showErrorToast(
          "Custom Objects are only available for customers on our Professional and Enterprise plans.",
        );
        return result;
      }
    });
  };

  return (
    <Row>
      <Col>
        <SectionHeaderWrapper
          title={<>Syncing Custom Objects</>}
          subtitle={
            <>
              <p className="mb-3">Customize the syncing of your custom objects by category.</p>{" "}
              <p className="mb-3">
                Enabling a category allows you to read and write custom data models from your users'
                third-party platforms directly through Merge's Unified API, just like Common Models.
              </p>
            </>
          }
          button={
            <MergeButton
              size="sm"
              variant={ButtonVariant.TertiaryWhite}
              onClick={() => openInNewTab(DOCS_CUSTOM_OBJECTS_PATH)}
              leftIcon={<BookOpen size={12} />}
            >
              Custom Objects docs
            </MergeButton>
          }
        />
        <Alert className="mb-8 mt-5" color="yellow" showWarningIcon>
          You are responsible for notifying your users about changes and obtaining consent to sync
          their data
        </Alert>
        <Card className="w-100">
          <Card.Body className="min-h-[108px] p-6">
            <SectionHeaderWrapper
              title="Categories"
              subtitle={
                <>
                  Enabling a category will sync{" "}
                  <a href={DOCS_API_REF_CUSTOM_OBJECTS_PATH} target="_blank" rel="noreferrer">
                    Custom Objects
                  </a>
                  ,{" "}
                  <a
                    href={DOCS_API_REF_CUSTOM_OBJECT_CLASSES_PATH}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Custom Object Classes
                  </a>
                  ,{" "}
                  <a href={DOCS_API_REF_ASSOCIATIONS_PATH} target="_blank" rel="noreferrer">
                    Associations
                  </a>
                  , and{" "}
                  <a href={DOCS_API_REF_ASSOCIATION_TYPES_PATH} target="_blank" rel="noreferrer">
                    Association Types
                  </a>{" "}
                  for all integrations in that category
                </>
              }
            />
          </Card.Body>
          <hr className="m-0" />
          <ListGroup variant="flush">
            {Object.entries<CategoryIsActiveConfig>(CategoryDisplayConfig).map(
              ([category, { isActive }]) => (
                <ListGroup.Item
                  className="flex items-center min-h-[76px] text-[15px] p-6 leading-[22px]"
                  key={category}
                >
                  {dataHasLoaded ? (
                    <>
                      <Col className="font-semibold flex items-center p-0">
                        <div>
                          {lucideIconForAPICategory(category as APICategory, "md", "mr-3 mb-[3px]")}
                          {displayNameForAPICategory(category as APICategory)}
                        </div>
                      </Col>
                      <Col className="col-auto p-0">
                        {isActive ? (
                          <Toggle
                            checked={isCustomObjectsEnabledForCategory(category)}
                            label={
                              isCustomObjectsEnabledForCategory(category) ? "Enabled" : "Disabled"
                            }
                            onChange={() => {
                              handleStatusToggle(category as APICategory);
                            }}
                          />
                        ) : (
                          <Badge color="gray">Coming soon</Badge>
                        )}
                      </Col>
                    </>
                  ) : (
                    <div className="flex flex-row items-center justify-between w-full">
                      <SkeletonLoader height={12} width={238} borderRadius={2} />
                      <SkeletonLoader height={12} width={182} borderRadius={2} />
                    </div>
                  )}
                </ListGroup.Item>
              ),
            )}
          </ListGroup>
        </Card>
      </Col>
    </Row>
  );
};

export default ConfigurationSyncingCustomObjects;
