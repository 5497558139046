import { Badge } from "@merge-api/merge-javascript-shared";
import { AuthType, LinkedAccount } from "../../../../../../models/Entities";
import React from "react";
import { ArrowLeftRight, Link } from "lucide-react";

import SkeletonLoader from "../../../../../shared-components/SkeletonLoader";
import { IssueDescription, LinkedAccountStatuses } from "../../../../../../constants";
import AccountTypeBadge from "../../AccountTypeBadge";
import { IntegrationIssue } from "../../../../IntegrationsManagementEntities";

interface TitleContainerProps {
  linkedAccount: LinkedAccount | null;
  isOnOverviewPage: boolean | undefined;
  isOnIssuesPage: boolean;
  ongoingIntegrationIssue: IntegrationIssue | null;
  numberIntegrationIssueTypes: number;
  isDataWarehouseAccount: boolean;
}

const LinkedAccountTitleContainer: React.FC<TitleContainerProps> = ({
  linkedAccount,
  ongoingIntegrationIssue,
  numberIntegrationIssueTypes,
  isOnIssuesPage,
  isOnOverviewPage,
  isDataWarehouseAccount,
}) => {
  const isWarningIssue =
    ongoingIntegrationIssue?.error_description == IssueDescription.MISSING_PERMISSION ||
    ongoingIntegrationIssue?.error_description == IssueDescription.SFTP_MISSING_REQUIRED_FIELDS ||
    ongoingIntegrationIssue?.error_description == IssueDescription.SFTP_INCORRECTLY_FORMATTED_ROW;
  const errorBadge =
    (isOnOverviewPage || isOnIssuesPage) && ongoingIntegrationIssue ? (
      <Badge color={isWarningIssue && numberIntegrationIssueTypes == 1 ? "amber" : "red"} size="lg">
        {numberIntegrationIssueTypes > 1
          ? `${numberIntegrationIssueTypes} issues`
          : ongoingIntegrationIssue.error_description}
      </Badge>
    ) : undefined;

  const linkedAccountBadge =
    linkedAccount && linkedAccount.status == LinkedAccountStatuses.RELINK_NEEDED ? (
      <AccountTypeBadge
        className="leading-24 whitespace-nowrap"
        account={linkedAccount}
        isRelinkNeeded
        isFullText
      />
    ) : undefined;

  return (
    <div className="flex items-center justify-between flex-wrap gap-y-4">
      <div className="flex items-center flex-grow d-flex gap-3 min-w-[250px] text-[28px]">
        {linkedAccount ? (
          <div className="text-truncate-flex flex-shrink-1 flex items-center">
            {linkedAccount?.end_user?.organization_name}
          </div>
        ) : (
          <SkeletonLoader width={160} height={24} borderRadius={4} />
        )}

        {isDataWarehouseAccount ? (
          <Link className="flex-shrink-0" />
        ) : (
          <ArrowLeftRight className="flex-shrink-0" />
        )}

        {linkedAccount ? (
          <>
            <div className="flex-shrink-0">{linkedAccount?.integration?.name}</div>
            {linkedAccount?.auth_type === AuthType.SFTP && (
              <Badge className="ml-1.5 items-center gap-1" color="purple" size="lg">
                SFTP
              </Badge>
            )}
          </>
        ) : (
          <SkeletonLoader width={160} height={24} borderRadius={4} />
        )}
        {linkedAccountBadge || errorBadge}
      </div>
    </div>
  );
};

export default LinkedAccountTitleContainer;
