import { UnreleasedAPICategory } from "@merge-api/merge-javascript-shared";

export enum APICategory {
  hris = "hris",
  ats = "ats",
  accounting = "accounting",
  ticketing = "ticketing",
  crm = "crm",
  mktg = "mktg",
  filestorage = "filestorage",
}

export enum LinkedAccountStatus {
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
  RELINK_NEEDED = "RELINK_NEEDED",
}

export enum SDKLanguage {
  Python = "Python",
  Ruby = "Ruby",
  NodeJS = "NodeJS",
  Go = "Go",
  Elixir = "Elixir",
  Java = "Java",
  TypeScript = "TypeScript",
}

export enum CommonModelSyncStatusType {
  SYNCING = "SYNCING",
  DONE = "DONE",
  FAILED = "FAILED",
  DISABLED = "DISABLED",
  PAUSED = "PAUSED",
  PARTIALLY_SYNCED = "PARTIALLY_SYNCED",
}

export enum DataWarehouseSyncStatusType {
  SYNCING = "SYNCING",
  PENDING = "PENDING",
  FAILED = "FAILED",
  COMPLETE = "COMPLETE",
}

export enum DataWarehouseConnectionType {
  SOURCE = "SOURCE",
  DESTINATION = "DESTINATION",
}

export enum UserType {
  admin_with_billing = "ADMIN_WITH_BILLING",
  admin_without_billing = "ADMIN_WITHOUT_BILLING",
  member = "MEMBER",
}

export enum PreexistingProductionLinkedAccountPreference {
  CREATE_AS_NEW_END_USER = "CREATE_AS_NEW_END_USER",
  USE_PREEXISTING_END_USER = "USE_PREEXISTING_END_USER",
  BLOCK = "BLOCK",
}

export enum BillingGuardrailsStatus {
  BILLING_STATUS_FREE = "BILLING_STATUS_FREE",
  BILLING_STATUS_LAUNCH = "BILLING_STATUS_LAUNCH",
  BILLING_STATUS_LAUNCH_APPROACHING_GUARDRAILS = "BILLING_STATUS_LAUNCH_APPROACHING_GUARDRAILS",
  BILLING_STATUS_LAUNCH_ACCOUNT_AT_LIMIT_NO_GUARDRAILS = "BILLING_STATUS_LAUNCH_ACCOUNT_AT_LIMIT_NO_GUARDRAILS",
  BILLING_STATUS_LAUNCH_ACCOUNT_AT_LIMIT_GUARDRAILS = "BILLING_STATUS_LAUNCH_ACCOUNT_AT_LIMIT_GUARDRAILS",
  BILLING_STATUS_LAUNCH_ACCOUNT_OVER_LIMIT_NO_GUARDRAILS = "BILLING_STATUS_LAUNCH_ACCOUNT_OVER_LIMIT_NO_GUARDRAILS",
  BILLING_STATUS_LAUNCH_ACCOUNT_OVER_LIMIT_GUARDRAILS = "BILLING_STATUS_LAUNCH_ACCOUNT_OVER_LIMIT_GUARDRAILS",
  BILLING_STATUS_FREE_AFTER_APRIL_2023 = "BILLING_STATUS_FREE_AFTER_APRIL_2023",
  BILLING_STATUS_LAUNCH_AFTER_APRIL_2023 = "BILLING_STATUS_LAUNCH_AFTER_APRIL_2023",
  BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_APPROACHING_CHARGE_LIMIT = "BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_APPROACHING_CHARGE_LIMIT",
  BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_UPSELL_MOMENT = "BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_UPSELL_MOMENT",
}

export enum InvoiceNonpaymentStatus {
  BILLING_STATUS_OVERDUE_INVOICE_MISSING_PAYMENT_METHOD = "BILLING_STATUS_OVERDUE_INVOICE_MISSING_PAYMENT_METHOD",
  BILLING_STATUS_OVERDUE_INVOICE_FAILED_PAYMENT = "BILLING_STATUS_OVERDUE_INVOICE_FAILED_PAYMENT",
  BILLING_STATUS_OVERDUE_INVOICE_MISSING_PAYMENT_METHOD_PAST_30 = "BILLING_STATUS_OVERDUE_INVOICE_MISSING_PAYMENT_METHOD_PAST_30",
  BILLING_STATUS_OVERDUE_INVOICE_FAILED_PAYMENT_PAST_30 = "BILLING_STATUS_OVERDUE_INVOICE_FAILED_PAYMENT_PAST_30",
}

export const GUARDRAILS_ENFORCED_STATUSES = [
  BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_ACCOUNT_AT_LIMIT_GUARDRAILS,
  BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_ACCOUNT_OVER_LIMIT_GUARDRAILS,
];

export const INVOICE_FE_DISABLED_STATUSES = [
  InvoiceNonpaymentStatus.BILLING_STATUS_OVERDUE_INVOICE_FAILED_PAYMENT_PAST_30,
  InvoiceNonpaymentStatus.BILLING_STATUS_OVERDUE_INVOICE_MISSING_PAYMENT_METHOD_PAST_30,
];

export enum SyncFrequencyPlan {
  SYNC_FREQUENCY_PLAN_HIGHEST = "SYNC_FREQUENCY_PLAN_HIGHEST",
  SYNC_FREQUENCY_PLAN_HIGHEST_WITH_DELETED_DATA = "SYNC_FREQUENCY_PLAN_HIGHEST_WITH_DELETED_DATA",
  SYNC_FREQUENCY_PLAN_HIGHEST_MANUAL = "SYNC_FREQUENCY_PLAN_HIGHEST_MANUAL",
  SYNC_FREQUENCY_PLAN_DAILY = "SYNC_FREQUENCY_PLAN_DAILY",
  SYNC_FREQUENCY_PLAN_MONTHLY = "SYNC_FREQUENCY_PLAN_MONTHLY",
  SYNC_FREQUENCY_PLAN_QUARTERLY = "SYNC_FREQUENCY_PLAN_QUARTERLY",
  SYNC_FREQUENCY_PLAN_DEFAULT = "DEFAULT",
}

export const ManualSyncCreditPlans = [
  SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_QUARTERLY,
  SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_MONTHLY,
];

export interface Organization {
  id: string;
  name: string;
  logo: string;
  auto_enable_new_integrations: boolean;
  enable_auto_webhook_setup: boolean;
  should_show_requested_data_in_linking_flow: boolean;
  is_mfa_required: boolean;
  preexisting_production_linked_account_preference: PreexistingProductionLinkedAccountPreference;
  show_selective_sync?: boolean;
  merge_link_error_remediation_enabled?: boolean;
  sync_frequency_plans?: { [category: string]: SyncFrequencyPlan };
  billed_category_linked_account_count?: { [category: string]: number };
  purchased_prod_linked_accounts?: number;
  should_hide_merge_logo?: boolean;
}

export interface PendingInvite {
  id: string;
  email: string;
  organization: Organization;
}

export interface User {
  is_active?: boolean;
  id: number; // do not use
  uuid: string;
  name: string;
  email: string;
  avatar: string | null;
  organization: Organization;
  type: UserType;
  is_staff: boolean;
  is_two_factor_enabled: boolean;
  terms_agreement_datetime: string;
  pylon_hmac: string;
  allowed_invite_roles: Array<UserType>;
  is_billing_notice_enabled: boolean;
  is_selective_sync_enabled: boolean;
  is_g2_survey_enabled: boolean;
  is_integration_wide_field_mappings_enabled: boolean;
  g2_banner_dismiss_count: number;
  g2_banner_last_dismiss_date: string;
  disable_magic_link_onboarding: boolean;

  /**
   * If the redesign of logs search is enabled - will swap the contents of /logs
   */
  is_webhook_search_enabled?: boolean;

  is_onboarding_v2_enabled?: boolean;
  show_upgrade_billing_plan_banner?: boolean;
  is_pricing_banner_dismissed?: boolean;
  can_toggle_merge_link_error_remediation_enabled?: boolean;
  is_linked_account_scopes_dashboard_enabled?: boolean;
  is_issues_v2_enabled?: boolean;
  is_override_common_models_enabled?: boolean;
  first_prod_linked_account_created_at?: string;
  are_end_user_configurable_scopes_enabled?: boolean;
  is_field_mapping_and_override_experience_enabled?: boolean;
  is_field_mapping_coverage_enabled?: boolean;
  is_field_mapping_preview_enabled?: boolean;
  is_file_storage_selective_sync_enabled?: boolean;
  is_field_mapping_advanced_enabled?: boolean;
  is_common_model_scopes_dependencies_enabled?: boolean;
  is_partnership_credential_modal_enabled?: boolean;
  is_free_account_gating_enabled?: boolean;
}

export interface OrganizationConfigStatusField {
  completed: boolean;
  description: string;
  field_name: string;
  field_type: string;
  required: boolean;
}

export interface Integration {
  id: string;
  name: string;
  abbreviated_name?: string;
  notes_for_customers?: string;
  image: string;
  square_image?: string;
  dark_grey_image?: string;
  light_grey_image?: string;
  coverage: string;
  base_api_url: string;
  modified_at: string;
  active: boolean;
  categories: Array<string>;
  is_enabled?: boolean;
  is_auto_webhook_setup_enabled?: boolean;
  organization_config_status: OrganizationConfigStatusField[];
  slug: string;
  api_documentation_url: string;
}

export type LinkedIntegration = Pick<Integration, "id" | "name" | "categories" | "square_image">;

export enum BlueprintSpeed {
  SLOW = "SLOW",
  INTERMEDIATE = "INTERMEDIATE",
  FAST = "FAST",
  CUSTOM = "CUSTOM",
}

export enum PartnershipType {
  NONE = "NONE",
  ORGANIZATION_PARTNERSHIP = "ORGANIZATION_PARTNERSHIP",
}

export interface OrganizationIntegrationSettings {
  id: string;
  organization: Organization;
  organization_config_status: OrganizationConfigStatusField[];
  category: string;
  integration: Integration;
  is_beta: boolean;
  is_enabled: boolean;
  is_toggleable: boolean;
  display_order: number | undefined;
  blueprint_speed: BlueprintSpeed;
  partnership_available: boolean;
  partnership_type: PartnershipType;
  available_blueprint_speeds: Array<BlueprintSpeed>;
  has_merge_creds: boolean;
}

export interface EndUser {
  id: string;
  organization: Organization;
  origin_id: string;
  email_address: string;
  organization_name: string;
  organization_logo: string;
}

export enum PauseReason {
  PRICING_PLAN_LIMIT = "PRICING_PLAN_LIMIT",
  LINKED_ACCOUNT_INACTIVITY = "LINKED_ACCOUNT_INACTIVITY",
  FAILING_SYNCS = "FAILING_SYNCS",
  PLAN_LIMIT_REACHED = "PLAN_LIMIT_REACHED",
}

export enum AuthType {
  OAUTH1 = "OAUTH1",
  OAUTH2 = "OAUTH2",
  BASIC = "BASIC",
  BASIC_WITH_TOKEN_EXCHANGE = "BASIC_WITH_TOKEN_EXCHANGE",
  SCRAPER = "SCRAPER",
  SFTP = "SFTP",
  DATA_WAREHOUSE = "DATA_WAREHOUSE",
}

export interface LinkedAccount {
  id: string;
  integration: Integration;
  category: APICategory | UnreleasedAPICategory;
  created_at: string;
  completed_at: string;
  organization: Organization;
  end_user: EndUser;
  override_base_api_url: string;
  public_token: string;
  is_test_account: string;
  account_token: string;
  is_duplicate?: boolean | null;
  status: LinkedAccountStatus;
  status_detail?: string | null;
  pause_reason: PauseReason | null;
  custom_mapping_instance_count: number;
  total_sync_credits?: number;
  remaining_sync_credits?: number;
  sync_credits_refresh_timestamp?: string;
  sync_frequency_plan?: SyncFrequencyPlan;
  should_redact_unmapped_data: boolean;
  redact_unmapped_data_enabled_for_org: boolean;
  sync_connection_id: string;
  auth_type: AuthType;
}

export interface APITesterLinkedAccount
  extends Pick<
    LinkedAccount,
    | "id"
    | "integration"
    | "override_base_api_url"
    | "category"
    | "end_user"
    | "is_test_account"
    | "status"
  > {}

export interface LinkedAccountMetrics {
  completed_linked_accounts_count: number;
  incomplete_linked_accounts_count: number;
  relink_needed_accounts_count: number;
  idle_accounts_count: number;
  plan_limit_reached_accounts_count: number;
}

export enum DestinationStatus {
  COMPLETE = "COMPLETE",
  RELINK_NEEDED = "RELINK_NEEDED",
}

export interface Destination {
  name: string;
  category: APICategory;
  id: string;
  status: DestinationStatus;
  integration: Integration;
}

export interface Webhook {
  id: string;
  events: string[];
  target: string;
}

export interface FieldMappingTarget {
  id: string;
  category: APICategory;
  common_model_id: string;
  field_key: string;
  field_description: string;
  common_model_name: string;
  weight: number;
  is_mappable_in_link: boolean;
  total_int_wide_field_mappings: number;
  is_remote_data_enabled: boolean;
}

export interface IntegrationWideFieldMappingInstanceInfo {
  id: string;
  origin_field: string | undefined;
  display_name: string | undefined;
  field_description: string | undefined;
  remote_endpoint_path: string | undefined;
  enable_linked_account_level_overrides: boolean;
  field_traversal_path: Array<string>;
  remote_endpoint_method: string | undefined;
  origin_type: string | undefined;
}
export interface AvailableFieldMappingIntegration {
  integration_info: Integration;
  field_mapping_info?: IntegrationWideFieldMappingInstanceInfo;
}

export interface FieldMappingCommonModelConfiguration {
  id: string;
  common_model_name: string;
  allow_user_created_field_mapping_targets: boolean;
  has_remote_data_enabled: boolean;
}
export interface CustomIntegration {
  id: string;
  name: string;
  image: ImageBitmap;
  active: boolean;
  category: string;
}

export interface SetupStep {
  id: string;
  step_number: number;
  title: string;
  description: string;
}

export enum APIEndpointMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
}

export interface Comment {
  id: string;
  user: User;
  body: string;
  created_at: string;
}

export interface CommonModelSyncStatus {
  is_initial_sync: boolean;
  last_sync_start: string;
  next_sync_start: string;
  model_id: string;
  model_name: string;
  status: CommonModelSyncStatusType;
  sync_reason?: string;
}

export interface DataWarehouseSyncConnectionConfig {
  id: string;
  name: string;
  integration: string;
  status: string;
  connection_type: string;
  schema_id: string;
  stage_name: string;
}

export interface DataWarehouseDestinationConnectionConfig {
  id: string;
  name: string;
  integration: Integration;
  status: string;
  connection_type: string;
  schema_id: string;
  stage_name: string;
}

export interface DataWarehouseSyncStateOverview {
  initiated_at: string;
  status: DataWarehouseSyncStatusType;
  total_pages: string;
}

export interface DataWarehouseImportConfig {
  import_id: string;
  source_connection_id: string;
  source_connection: DataWarehouseSyncConnectionConfig;
  destination_connection_id: string;
  destination_connection: DataWarehouseDestinationConnectionConfig;
  sync_schedule_status: string;
  sync_schedule_next_run_at: string;
  active_execution_id: string;
  active_execution_details: DataWarehouseSyncStateOverview;
  latest_execution_id: string;
  latest_execution_details: DataWarehouseSyncStateOverview;
}
export interface DataWarehouseImportConfigList {
  import_configs: Array<DataWarehouseImportConfig>;
}

export enum HTTPMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
  OPTIONS = "OPTIONS",
  HEAD = "HEAD",
}

export interface WebhookReceiver {
  event: string;
  is_active: boolean;
  is_awaiting_handshake: boolean;
  key?: string;
  last_received_at?: string;
  webhook_listener_url: string | null;
}

export interface LinkedAccountWebhookReceivers {
  webhook_receivers: Array<WebhookReceiver>;
  webhook_listener_url: string | null;
  webhook_listener_instructions: string | null;
  integration_uses_webhook_handshake: boolean;
  integration_uses_app_level_secret_key: boolean;
  uses_org_level_signature_key: boolean;
}

export type ConditionValueType = string | Array<string> | undefined;

export interface SelectiveSyncCondition {
  condition_schema_id: string;
  common_model: string;
  native_name: string | undefined;
  field_name: string | undefined;
  operator: string | undefined;
  value: ConditionValueType;
  can_fetch_filter_options: boolean | undefined;
}

export interface LinkedAccountSelectiveSyncCondition extends SelectiveSyncCondition {
  id: string;
}

export enum ConditionType {
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  DATE_TIME = "DATE_TIME",
  INTEGER = "INTEGER",
  FLOAT = "FLOAT",
  STRING = "STRING",
}

export interface ConditionOperatorSchema {
  operator: string;
  is_unique?: boolean;
}

export interface SelectiveSyncConditionSchema {
  id: string;
  common_model: string;
  field_name: string;
  native_name: string;
  can_fetch_filter_options: boolean;
  condition_type: ConditionType;
  operators: ConditionOperatorSchema[];
  choices?: string[];
}

export enum ConditionOperator {
  EQUALS = "EQUALS",
  NOT_EQUALS = "NOT_EQUALS",
  GREATER_THAN = "GREATER_THAN",
  GREATER_THAN_OR_EQUAL_TO = "GREATER_THAN_OR_EQUAL_TO",
  LESS_THAN = "LESS_THAN",
  LESS_THAN_OR_EQUAL_TO = "LESS_THAN_OR_EQUAL_TO",
  IS_ONE_OF = "IS_ONE_OF",
  HAS_ALL_OF = "HAS_ALL_OF",
  HAS_NONE_OF = "HAS_NONE_OF",
  WITHIN = "WITHIN",
}

export interface FieldMappingTargetsAndFieldMappingInstances {
  field_mapping_instances: Array<FieldMappingInstance>;
  field_mapping_targets: Array<FieldMappingTarget>;
  overridden_common_model_instances: Array<OverriddenCommonModelInstance>;
  overridden_common_model_targets: Array<OverriddenCommonModelTarget>;
}

export interface FieldMappingInstance {
  field_key: string;
  id: string;
  field_description: string;
  configured_by: string;
  field_traversal_path: Array<string>;
  common_model_name: string;
  field_mapping_target: FieldMappingTarget | null;
  display_name: string;
  is_integration_wide: boolean;
  enable_linked_account_level_overrides: boolean;
  remote_endpoint_path: string;
  remote_endpoint_method: string;
  origin_type: string;
}

export interface OverriddenCommonModelInstance {
  id: string;
  field_traversal_path: Array<string>;
  common_model_name: string;
  overridden_field: string;
  overridden_field_definition: FieldDefinition | null;
  override_common_model_target: OverriddenCommonModelTarget;
  remote_endpoint_path: string;
  remote_endpoint_method: string;
  origin_type: string | null;
  origin_field: string | null;
  is_integration_wide: boolean;
  is_integration_wide_override_mapping: boolean;
  display_name: string | null;
}

export type FieldDefinition = {
  title: string;
  type: string;
  description: string;
  format?: string | null;
};

export interface CreateOverriddenCommonModelInstanceDict {
  integration_id?: string;
  organization_id?: string;
  linked_account_id?: string;
  common_model_id?: string;
  overridden_field?: string;
  field_traversal_path: string[];
  api_endpoint_id: string;
  override_common_model_target_id?: string;
  display_name?: string;
}

export interface EditOverriddenCommonModelInstanceDict {
  api_endpoint_id: string;
  field_traversal_path: string[];
  display_name?: string;
}

export interface CreateOrgWideCommonModelOverrideTargetDict {
  common_model_id: string;
  overridden_field: string;
}
export interface OverriddenCommonModelTarget {
  id: string;
  overridden_field: string;
  common_model_name: string;
  category: string;
  overridden_field_definition: FieldDefinition | null;
  total_int_wide_overrides: number | null;
  is_remote_data_enabled: boolean;
}

export type OverrideModelAndFieldOptionsValue = {
  available_fields: string[];
  common_model_name: string;
  allowed_overridden_fields_configs: CommonModelFieldDefinition[];
};
export interface OverrideModelAndFieldOptions {
  [model_name: string]: OverrideModelAndFieldOptionsValue;
}

export interface MergeTypeaheadOption {
  id: string;
  label: string;
}
export interface OverrideCommonModelIntegrationInfo {
  integration: Integration;
  common_model_override_instance: OverriddenCommonModelInstance | null;
}
export type FieldMappingCreationAndEditDict = {
  api_endpoint_id: string;
  display_name: string;
  traversal_path: string;
  type: string;
  format: string | null;
  api_endpoint_path: string;
  api_endpoint_method: string;
  value: string;
  key: string;
  commonModelName: string;
  percentage_availability?: number;
};

export type FieldMappingAPIEndpointToFieldsMap = {
  [api_endpoint_id: string]: {
    [traversal_path: string]: FieldMappingCreationAndEditDict;
  };
};
// dict of common_model -> api_endpoint_id -> {field_path: type}
export type FieldMappingOptions = {
  [common_model_name: string]: FieldMappingAPIEndpointToFieldsMap;
};

export type CommonModelFieldDefinition = {
  field_type: string;
  field_name: string;
  field_description: string;
  field_format: string | null;
};

export type ModelAndFieldOverrideDict = {
  [common_model_id: string]: {
    available_fields: string[];
    common_model_name: string;
    allowed_overridden_fields_configs: CommonModelFieldDefinition[];
  };
};
export type FieldMappingMetaResponse = {
  available_field_mappings: FieldMappingOptions;
  api_documentation_url: string | undefined;
  available_model_and_field_overrides: ModelAndFieldOverrideDict | null;
  field_mapping_common_model_configurations: FieldMappingCommonModelConfiguration[];
};

export type FieldMappingOptionsResponse = {
  remote_field_options: FieldMappingOptions;
};

export type Log = {
  id: string;
  linked_account: LinkedAccount;
  method: string;
  response_code: number;
  created_at: string;
  url: string;
};

export type IntegrationIssueMetadata = {
  action: string;
  status_code: number;
  user_facing_error_detail: string;
};

export type IntegrationIssue = {
  id: string;
  error_description: string;
  metadata: IntegrationIssueMetadata[];
};

type CommonModelToggleScopeLevelInfo = {
  org_scoped_actions: string[];
  linked_account_scoped_actions: string[];
  org_scoped_fiedls: string[];
  linked_account_scoped_fields: string[];
};
export type CommonModelToggle = {
  name: string;
  enabled_model_actions: string[];
  fields: CommonModelField[];
  model_capabilities: string[];
  model_description: string;
  end_user_configurable_actions: string[];
  scope_level_info: CommonModelToggleScopeLevelInfo;
};

export type CateogryScopeMap = {
  [key: string]: CommonModelScopeMap;
};

export type CommonModelScopeMap = {
  actions: string[];
  capabilities: string[];
  fields: CommonModelFieldMap;
  linkedAccountActionOverrides: string[];
  linkedAccountFieldOverrides: string[];
};

export type CommonModelField = {
  field_name: string;
  is_enabled: boolean;
  field_description: string;
  field_type: string;
};
export type CommonModelFieldMap = {
  [key: string]: {
    isEnabled: boolean;
    fieldDescription: string;
    fieldType: string;
  };
};

export type CommonModelFieldLevelMapping = {
  isEnabled: boolean;
};

// Audit Logs
export enum AuditLogEventType {
  CREATED_REMOTE_PRODUCTION_API_KEY = "CREATED_REMOTE_PRODUCTION_API_KEY",
  DELETED_REMOTE_PRODUCTION_API_KEY = "DELETED_REMOTE_PRODUCTION_API_KEY",
  CREATED_TEST_API_KEY = "CREATED_TEST_API_KEY",
  DELETED_TEST_API_KEY = "DELETED_TEST_API_KEY",
  REGENERATED_PRODUCTION_API_KEY = "REGENERATED_PRODUCTION_API_KEY",
  INVITED_USER = "INVITED_USER",
  DEACTIVATED_USER = "DEACTIVATED_USER",
  ACTIVATED_USER = "ACTIVATED_USER",
  UPDATED_ROLE_FOR_USER = "UPDATED_ROLE_FOR_USER",
  TWO_FACTOR_AUTH_ENABLED = "TWO_FACTOR_AUTH_ENABLED",
  TWO_FACTOR_AUTH_DISABLED = "TWO_FACTOR_AUTH_DISABLED",
  TWO_FACTOR_AUTH_MODIFIED = "TWO_FACTOR_AUTH_MODIFIED",
  DELETED_LINKED_ACCOUNT = "DELETED_LINKED_ACCOUNT",
  CHANGED_SCOPES = "CHANGED_SCOPES",
  CHANGED_PERSONAL_INFORMATION = "CHANGED_PERSONAL_INFORMATION",
  CHANGED_ORGANIZATION_SETTINGS = "CHANGED_ORGANIZATION_SETTINGS",
  ENABLED_INTEGRATION = "ENABLED_INTEGRATION",
  DISABLED_INTEGRATION = "DISABLED_INTEGRATION",
  DISABLED_CATEGORY = "DISABLED_CATEGORY",
  ENABLED_CATEGORY = "ENABLED_CATEGORY",
  CHANGED_PASSWORD = "CHANGED_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  ENABLED_REDACT_UNMAPPED_DATA_FOR_ORGANIZATION = "ENABLED_REDACT_UNMAPPED_DATA_FOR_ORGANIZATION",
  ENABLED_REDACT_UNMAPPED_DATA_FOR_LINKED_ACCOUNT = "ENABLED_REDACT_UNMAPPED_DATA_FOR_LINKED_ACCOUNT",
  DISABLED_REDACT_UNMAPPED_DATA_FOR_ORGANIZATION = "DISABLED_REDACT_UNMAPPED_DATA_FOR_ORGANIZATION",
  DISABLED_REDACT_UNMAPPED_DATA_FOR_LINKED_ACCOUNT = "DISABLED_REDACT_UNMAPPED_DATA_FOR_LINKED_ACCOUNT",
  CREATED_INTEGRATION_WIDE_FIELD_MAPPING = "CREATED_INTEGRATION_WIDE_FIELD_MAPPING",
  CREATED_LINKED_ACCOUNT_FIELD_MAPPING = "CREATED_LINKED_ACCOUNT_FIELD_MAPPING",
  CHANGED_INTEGRATION_WIDE_FIELD_MAPPING = "CHANGED_INTEGRATION_WIDE_FIELD_MAPPING",
  CHANGED_LINKED_ACCOUNT_FIELD_MAPPING = "CHANGED_LINKED_ACCOUNT_FIELD_MAPPING",
  DELETED_INTEGRATION_WIDE_FIELD_MAPPING = "DELETED_INTEGRATION_WIDE_FIELD_MAPPING",
  DELETED_LINKED_ACCOUNT_FIELD_MAPPING = "DELETED_LINKED_ACCOUNT_FIELD_MAPPING",
  CONVERTED_TEST_LINKED_ACCOUNT_TO_PRODUCTION = "CONVERTED_TEST_LINKED_ACCOUNT_TO_PRODUCTION",
  FORCED_LINKED_ACCOUNT_RESYNC = "FORCED_LINKED_ACCOUNT_RESYNC",
  MUTED_ISSUE = "MUTED_ISSUE",
  GENERATED_MAGIC_LINK = "GENERATED_MAGIC_LINK",
  END_USER_CREDENTIALS_ACCESSED = "END_USER_CREDENTIALS_ACCESSED",
}

export enum AuditLogRole {
  ADMIN = "ADMIN",
  DEVELOPER = "DEVELOPER",
  MEMBER = "MEMBER",
  MERGE_TEAM = "MERGE_TEAM",
  SYSTEM = "SYSTEM",
  API = "API",
}

export type AuditLogEvent = {
  id: string;
  created_at: Date;
  user_name?: string;
  user_email?: string;
  role: AuditLogRole;
  event_description: string;
  event_type: AuditLogEventType;
  ip_address: string;
};

export enum SyncConnectionAuthConfigAuthSchemaFieldType {
  INPUT = "INPUT",
  JSON = "JSON",
}

export type SyncConnectionAuthConfigAuthSchema = {
  header: string;
  fields: {
    key: string;
    placeholder: string;
    required?: boolean;
    type: SyncConnectionAuthConfigAuthSchemaFieldType;
  }[];
}[];

export type SyncConnectionAuthConfig = {
  auth_schema: SyncConnectionAuthConfigAuthSchema;
  description: string;
  help_guide_url: string;
};

export type SyncConnectionIntegration = {
  id: string;
  slug: string;
  image: string;
  square_image: string;
  name?: string;
  color?: string;
  sync_connection_auth_config: {
    source?: SyncConnectionAuthConfig;
    destination?: SyncConnectionAuthConfig;
  };
};

export type ConditionPreset = {
  id: string;
  common_model: string;
  normalized_key_name: string;
  value: string | null;
  period: string | null;
  operator: ConditionOperator;
  is_end_user_configured: boolean;
};

export const USER_CONFIGURED_OPERATOR = "USER_CONFIGURED";

export type ConditionPresetMetaOperator = {
  operator: ConditionOperator | typeof USER_CONFIGURED_OPERATOR; // user configured operator is only used locally
  key_type: ConditionType | null;
  supported_integrations: Pick<Integration, "id" | "name" | "square_image">[];
};

export type ConditionPresetMetaField = {
  normalized_key_name: string;
  operators: ConditionPresetMetaOperator[];
};

export type ConditionPresetMeta = Record<string, ConditionPresetMetaField[]>;

export type LinkedAccountCondition = {
  id: string;
  condition_schema_id: string;
  common_model: string;
  normalized_key_name: string | undefined;
  remote_key_name: string | undefined;
  operator: ConditionOperator | undefined;
  value: ConditionValueType;
};

export type LinkedAccountConditionMetaOperator = {
  operator: ConditionOperator;
  key_type: ConditionType;
  condition_schema_id: string;
};

export type LinkedAccountConditionMetaField = {
  normalized_key_name: string;
  remote_key_name: string;
  operators: LinkedAccountConditionMetaOperator[];
};

export type LinkedAccountConditionMeta = Record<string, LinkedAccountConditionMetaField[]>;

export enum FileStorageSyncSelectionTypes {
  DRIVE = "DRIVE",
  FOLDER = "FOLDER",
}

export type LinkedAccountFileStorageFilter = {
  id: string;
  name: string;
  parent_remote_id: string | undefined;
  size: string | undefined;
  type: FileStorageSyncSelectionTypes;
  updated_at: string | undefined;
};

export type LinkedAccountFileStorageFilters = LinkedAccountFileStorageFilter[];

export type OrganizationFieldMappingValue = {
  allow_user_created_field_mapping_targets: boolean;
  common_model_id: string;
  has_remote_data_enabled: boolean;
  model_configuration_id: string | null;
  override_common_model_targets: OverriddenCommonModelTarget[];
  field_mapping_targets: FieldMappingTarget[];
  allowed_overridden_fields_configs: CommonModelFieldDefinition;
};

export type OrganizationFieldMappingInfo = {
  [common_model_id: string]: OrganizationFieldMappingValue;
};

type MappingPreview = {
  mapping_id: string;
};
export type FieldMappingPreview = {
  field_mapping_previews: MappingPreview;
  override_previews: MappingPreview;
};

export type LinkedAccountConfigurationCounts = {
  linked_account_field_mappings_count: number;
  org_wide_field_mappings_count: number;
  linked_account_common_model_overrides_count: number;
  org_wide_common_model_overrides_count: number;
  active_webhook_receivers_count: number;
  selective_sync_filters_count: number;
};
