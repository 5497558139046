import React from "react";
import { Info } from "lucide-react";
import {
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
  LinkedAccountConditionMetaField,
} from "../../../../../../../../models/Entities";
import { Select, Text } from "@merge-api/merge-javascript-shared";
import { getFilteredFieldOptions, getFieldValue } from "../helpers";
import { snakeCaseToSentenceCase } from "../../../../../../../../utils/casing";
import UpsellFilterOptionMessage from "../../../../../../../configuration/selective-sync/ConditionPresetInputCombo/components/UpsellFilterOptionMessage";

type FieldSelectProps = {
  integrationName: string;
  linkedAccountCondition: Partial<LinkedAccountCondition>;
  linkedAccountConditionMeta: LinkedAccountConditionMeta;
  linkedAccountConditions: Partial<LinkedAccountCondition>[];
  onLinkedAccountConditionChange: (LinkedAccountCondition: Partial<LinkedAccountCondition>) => void;
  showGatedSelectiveSync: boolean;
};

const FieldSelect = ({
  integrationName,
  linkedAccountCondition,
  linkedAccountConditionMeta,
  linkedAccountConditions,
  onLinkedAccountConditionChange,
  showGatedSelectiveSync,
}: FieldSelectProps) => {
  // derived state
  const fieldOptions = getFilteredFieldOptions(
    linkedAccountCondition,
    linkedAccountConditionMeta,
    linkedAccountConditions,
  );
  const fieldValue = getFieldValue(linkedAccountCondition, linkedAccountConditionMeta);

  // event handlers
  const onFieldChange = (_: any, fieldOption: LinkedAccountConditionMetaField | null) => {
    onLinkedAccountConditionChange({
      ...linkedAccountCondition,
      normalized_key_name: fieldOption?.normalized_key_name || undefined,

      // reset other fields
      condition_schema_id: undefined,
      operator: undefined,
      value: undefined,
    });
  };

  const selectProps = {
    className: "flex flex-1 h-full py-[3px]",
    shadowHidden: true,
    placeHolder: "Select field...",
    clearable: false,
    value: fieldValue,
  };

  return showGatedSelectiveSync ? (
    <Select
      {...selectProps}
      options={[fieldValue]}
      getOptionLabel={(option) => snakeCaseToSentenceCase(option?.normalized_key_name)}
      renderOption={(option) => (
        <div className="flex flex-row items-center">
          <Text className="text-gray-90 mr-2">
            {snakeCaseToSentenceCase(option?.normalized_key_name)}
          </Text>
          <Text variant="caption" className="text-gray-60">
            {option?.remote_key_name}
          </Text>
        </div>
      )}
      renderValue={(option) => <>{snakeCaseToSentenceCase(option?.normalized_key_name)}</>}
      footer={
        <>
          <div className="border-t-gray-20 border-t bg-gray-0 text-gray-70 py-2.5 px-3 flex items-center -mb-2">
            <Info size={16} className="mr-1.5" />{" "}
            <Text variant="sm">{integrationName} fields in small text</Text>
          </div>
          <UpsellFilterOptionMessage />
        </>
      }
    />
  ) : (
    <Select
      {...selectProps}
      options={fieldOptions}
      clearable={false}
      getOptionLabel={(option) => snakeCaseToSentenceCase(option.normalized_key_name)}
      renderOption={(option) => (
        <div className="flex flex-row items-center">
          <Text className="text-gray-90 mr-2">
            {snakeCaseToSentenceCase(option.normalized_key_name)}
          </Text>
          <Text variant="caption" className="text-gray-60">
            {option.remote_key_name}
          </Text>
        </div>
      )}
      renderValue={(option) => <>{snakeCaseToSentenceCase(option.normalized_key_name)}</>}
      onChange={onFieldChange}
      footer={
        <div className="border-t-gray-20 border-t bg-gray-0 text-gray-70 py-2.5 px-3 flex items-center -mb-2">
          <Info size={12} className="mr-1.5" />{" "}
          <Text variant="sm">{integrationName} fields in small text</Text>
        </div>
      }
    />
  );
};

export default FieldSelect;
