import React, { useEffect } from "react";
import {
  ConditionPreset,
  ConditionPresetMeta,
  ConditionPresetMetaOperator,
  USER_CONFIGURED_OPERATOR,
} from "../../../../../models/Entities";
import { Select, Text } from "@merge-api/merge-javascript-shared";
import { getOperatorOptions, getOperatorValue } from "../helpers";
import { Calculator } from "lucide-react";
import { operatorMap } from "../../constants";
import isUndefined from "lodash/isUndefined";
import UserConfiguredPlaceholder from "./UserConfiguredPlaceholder";
import UpsellFilterOptionMessage from "./UpsellFilterOptionMessage";

type OperatorSelectProps = {
  conditionPreset: Partial<ConditionPreset>;
  conditionPresetMeta: ConditionPresetMeta;
  onConditionPresetChange: (ConditionPreset: Partial<ConditionPreset>) => void;
  showGatedSelectiveSync: boolean;
};

const OperatorSelect = ({
  conditionPreset,
  conditionPresetMeta,
  onConditionPresetChange,
  showGatedSelectiveSync,
}: OperatorSelectProps) => {
  // derived state
  const operatorOptions = getOperatorOptions(conditionPreset, conditionPresetMeta);
  const operatorValue = getOperatorValue(conditionPreset, conditionPresetMeta);

  // event handlers
  const onOperatorChange = (_: any, option: ConditionPresetMetaOperator | null) => {
    if (option?.operator == USER_CONFIGURED_OPERATOR) {
      onConditionPresetChange({
        ...conditionPreset,
        operator: undefined,
        value: undefined,
        is_end_user_configured: true,
      });
    } else {
      onConditionPresetChange({
        ...conditionPreset,
        operator: option?.operator || undefined,
        is_end_user_configured: false,
      });
    }
  };

  // effects
  // set operatorOption to user configured if only one option exists
  useEffect(() => {
    if (
      operatorOptions.length === 1 && // only on operator option
      !conditionPreset.is_end_user_configured && // not already set to is_end_user_configured
      !isUndefined(conditionPreset.normalized_key_name) // there is a field selected already (if not then operator options being 1 is expected)
    ) {
      onConditionPresetChange({
        ...conditionPreset,
        operator: undefined,
        value: undefined,
        is_end_user_configured: true,
      });
    }
  }, [conditionPreset, onConditionPresetChange, operatorOptions.length]);

  if (operatorValue?.operator === USER_CONFIGURED_OPERATOR && operatorOptions.length === 1) {
    return <UserConfiguredPlaceholder />;
  }

  return showGatedSelectiveSync ? (
    <Select
      className="flex flex-1 h-full py-[3px]"
      placeholder="Select operator..."
      clearable={false}
      shadowHidden
      options={[operatorValue]}
      value={operatorValue}
      renderValue={(operatorObject) => {
        let option = operatorObject ? conditionPresetOperatorMap[operatorObject.operator] : null;

        if (operatorObject?.operator === USER_CONFIGURED_OPERATOR) {
          option = conditionPresetOperatorMap[USER_CONFIGURED_OPERATOR];
        }

        return <>{option?.title}</>;
      }}
      renderOption={(operatorObject) => {
        let option = operatorObject ? conditionPresetOperatorMap[operatorObject.operator] : null;

        if (operatorObject?.operator === USER_CONFIGURED_OPERATOR) {
          option = conditionPresetOperatorMap[USER_CONFIGURED_OPERATOR];
        }

        return (
          <div className="flex flex-col">
            <Text className="text-gray-90">{option?.title}</Text>
            <Text variant="caption" className="text-gray-60">
              {option?.subtitle}
            </Text>
          </div>
        );
      }}
      footer={<UpsellFilterOptionMessage />}
    />
  ) : (
    <Select
      className="flex flex-1 h-full py-[3px]"
      placeholder="Select operator..."
      clearable={false}
      shadowHidden
      options={operatorOptions}
      value={operatorValue}
      onChange={onOperatorChange}
      getOptionLabel={({ operator }) => {
        if (operator === USER_CONFIGURED_OPERATOR) {
          return "is user configured";
        }

        return conditionPresetOperatorMap[operator].title as string;
      }}
      renderValue={({ operator }) => {
        let option = conditionPresetOperatorMap[operator];

        if (operator === USER_CONFIGURED_OPERATOR) {
          option = conditionPresetOperatorMap[USER_CONFIGURED_OPERATOR];
        }

        return <>{option.title}</>;
      }}
      renderOption={({ operator }) => {
        let option = conditionPresetOperatorMap[operator];

        if (operator === USER_CONFIGURED_OPERATOR) {
          option = conditionPresetOperatorMap[USER_CONFIGURED_OPERATOR];
        }

        return (
          <div className="flex flex-col">
            <Text className="text-gray-90">{option.title}</Text>
            <Text variant="caption" className="text-gray-60">
              {option.subtitle}
            </Text>
          </div>
        );
      }}
    />
  );
};

export default OperatorSelect;

// constants
export const conditionPresetOperatorMap = {
  ...operatorMap,
  [USER_CONFIGURED_OPERATOR]: {
    title: (
      <div>
        <Calculator size={16} className="mr-1.5" />
        is user configured
      </div>
    ),
    subtitle: "Configurable by your user in Link",
  },
};

export const USER_CONFIGURED_OPERATOR_OPTION: ConditionPresetMetaOperator = {
  operator: USER_CONFIGURED_OPERATOR,
  key_type: null,
  supported_integrations: [],
};
