/// /////////////////////////////
/// / Blueprint JSON Parsing ////
/// /////////////////////////////

import React from "react";
import { APICategory } from "@merge-api/merge-javascript-shared";
import {
  MERGE_APP_PATH,
  MERGE_APP_APAC_PATH,
  MERGE_APP_EU_PATH,
  SIGNUP_PATH,
  LOGIN_PATH,
} from "./router/RouterUtils";
import UsaFlagImage from "./assets/svg/usa-flag.svg";
import EuFlagImage from "./assets/svg/eu-flag.svg";
import ApacFlagImage from "./assets/svg/apac-flag.svg";

export const STEP_TYPES = {
  API_REQUEST: "API_REQUEST",
  ARRAY_LOOP: "ARRAY_LOOP",
  IF_ELSE: "IF_ELSE",
  CREATE_OR_UPDATE: "CREATE_OR_UPDATE",
};

export const PUBLISH_STATES = {
  PUBLISHED: "PUBLISHED",
  UNPUBLISHED: "UNPUBLISHED",
  DRAFT: "DRAFT",
};

export const signupTenantOptions = {
  usa: {
    value: "usa",
    label: "USA",
    image: UsaFlagImage,
    url: MERGE_APP_PATH + SIGNUP_PATH,
  },
  eu: {
    value: "eu",
    label: "EU",
    image: EuFlagImage,
    url: MERGE_APP_EU_PATH + SIGNUP_PATH,
  },
  apac: {
    value: "apac",
    label: "APAC",
    image: ApacFlagImage,
    url: MERGE_APP_APAC_PATH + SIGNUP_PATH,
  },
};

export const loginTenantOptions = {
  usa: {
    value: "usa",
    label: "USA",
    image: UsaFlagImage,
    url: MERGE_APP_PATH + LOGIN_PATH,
  },
  eu: {
    value: "eu",
    label: "EU",
    image: EuFlagImage,
    url: MERGE_APP_EU_PATH + LOGIN_PATH,
  },
  apac: {
    value: "apac",
    label: "APAC",
    image: ApacFlagImage,
    url: MERGE_APP_APAC_PATH + LOGIN_PATH,
  },
};

export const COMPLETE_LINKED_CONST = "completed";
export const INCOMPLETE_LINKED_CONST = "incompleted";
/// /////////////////////////////

/// /////////////////////////////
/// /// HRIS Common Models //////
/// /////////////////////////////

export const HRIS_COMMON_MODEL_TYPES = {
  EMPLOYEE: "EMPLOYEE",
  LOCATION: "LOCATION",
  COMPANY: "COMPANY",
  EMPLOYMENT: "EMPLOYMENT",
  TEAM: "TEAM",
  TIME_OFF: "TIME_OFF",
  BENEFIT: "BENEFIT",
  DOCUMENT: "DOCUMENT",
  REPORT: "REPORT",
  PAYROLL_RUN: "PAYROLL_RUN",
  EMPLOYEE_PAYROLL_RUN: "EMPLOYEE_PAYROLL_RUN",
  TIME_OFF_BALANCE: "TIME_OFF_BALANCE",
};

/// /////////////////////////////
/// /// ATS Common Models //////
/// /////////////////////////////

export const ATS_COMMON_MODEL_TYPES = {
  REMOTE_USER: "REMOTE_USER",
  JOB: "JOB",
  DEPARTMENT: "DEPARTMENT",
  OFFICE: "OFFICE",
  CANDIDATE: "CANDIDATE",
  TAG: "TAG",
  APPLICATION: "APPLICATION",
  JOB_INTERVIEW_STAGE: "JOB_INTERVIEW_STAGE",
  SCHEDULED_INTERVIEW: "SCHEDULED_INTERVIEW",
  SCORECARD: "SCORECARD",
  REJECTION_REASON: "REJECTION_REASON",
  ATTACHMENT: "ATTACHMENT",
  OFFER: "OFFER",
  EEOC: "EEOC",
  ACTIVITY: "ACTIVITY",
};

/// /////////////////////////////
/// /// ACCOUNTING Common Models //////
/// /////////////////////////////

export const ACCOUNTING_COMMON_MODEL_TYPES = {
  ACCOUNT: "ACCOUNT",
  ACCOUNTING_ATTACHMENT: "ACCOUNTING_ATTACHMENT",
  BALANCE_SHEET: "BALANCE_SHEET",
  CASH_FLOW_STATEMENT: "CASH_FLOW_STATEMENT",
  COMPANYINFO: "COMPANY_INFO",
  CONTACT: "CONTACT",
  ACCOUNTING_PHONE_NUMBER: "ACCOUNTING_PHONE_NUMBER",
  ADDRESS: "ADDRESS",
  INCOMESTATEMENT: "INCOME_STATEMENT",
  REPORT_ITEM: "REPORT_ITEM",
  CREDIT_NOTE: "CREDIT_NOTE",
  ITEM: "ITEM",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  PURCHASE_ORDER_LINE_ITEM: "PURCHASE_ORDER_LINE_ITEM",
  TRACKING_CATEGORY: "TRACKING_CATEGORY",
  JOURNAL_ENTRY: "JOURNAL_ENTRY",
  JOURNAL_LINE: "JOURNAL_LINE",
  TAX_RATE: "TAX_RATE",
  INVOICE: "INVOICE",
  INVOICE_LINE_ITEM: "INVOICE_LINE_ITEM",
  PAYMENT: "PAYMENT",
};

/// /////////////////////////////
/// /// CUSTOM OBJECT Common Model Names //////
/// /////////////////////////////
export const CUSTOM_OBJECT_COMMON_MODEL_NAMES = [
  "CustomObjectClass",
  "CustomObject",
  "Association",
  "AssociationSubType",
  "AssociationType",
];

/// /////////////////////////////

/// /////////////////////////////
/// /         Time           ////
/// /////////////////////////////
export const SEC_IN_YEAR = 60 * 60 * 24 * 365;
export const SEC_IN_DAY = 60 * 60 * 24;

export const SEC_PASSWORD_RESET_AUTO_REDIRECT = 15;

/// /////////////////////////////

/// /////////////////////////////
/// /      Documentation      ////
/// /////////////////////////////
export const DOCUMENTATION_ORDER = [
  "link-token",
  "account-token",
  "regenerate-key",
  "available-actions",
  "passthrough",
];

/**
 * What stage in our deprecation process the commonModel/field/other is.
 * Reference: https://www.notion.so/mergeapi/Engineering-API-Deprecation-3aa97cd780524959804feb363c24840a
 */
export enum DeprecationStage {
  // notice is given to customers, marked as deprecated on frontend
  DEPRECATED = "DEPRECATED",

  // removed from docs/dashboard, but still functional via API (and SDKs)
  SUNSET = "SUNSET",

  // fully deleted from codebase, db tables potentially removed.
  RETIRED = "RETIRED",
}

export interface AdditionalEndpointInfo {
  title?: string;
  subtitle?: JSX.Element;
  isBeta?: boolean;
  deprecationStage?: DeprecationStage;
  deprecationTooltip?: string;
  headerWarningMessage?: JSX.Element | string;
  pricingMessage?: JSX.Element | string;
  warningMessage?: JSX.Element | string;
  category?: string;
}

interface AdditionalCommonModelInfo {
  [key: string]: {
    [key: string]: {
      deprecationStage?: DeprecationStage;
      deprecationMessage?: React.ReactNode;
      fields?: {
        [key: string]: {
          deprecationStage?: DeprecationStage;
          deprecationMessage?: React.ReactNode;
        };
      };
    };
  };
}
export const ADDITIONAL_COMMON_MODEL_INFO: AdditionalCommonModelInfo = {
  [APICategory.hris]: {
    Employee: {
      fields: {
        team: {
          deprecationStage: DeprecationStage.SUNSET,
        },
        hire_date: {
          deprecationStage: DeprecationStage.DEPRECATED,
        },
      },
    },
    Team: {
      deprecationStage: DeprecationStage.SUNSET,
      deprecationMessage: (
        <>
          Merge has deprecated this model and will no longer support it via API after{" "}
          <b>February 1, 2024</b>. We strongly recommend using the <code>Group</code> common model
          as it has the same capabilities as <code>Teams</code> and is more comprehensive.
        </>
      ),
    },
    Benefit: {
      fields: {
        benefit_plan_type: {
          deprecationStage: DeprecationStage.DEPRECATED,
          deprecationMessage: (
            <>
              Merge will no longer support this field after <b>8/1/2023</b>. We strongly recommend
              using the <code>Employer Benefit</code> Common Model to access this field.
            </>
          ),
        },
      },
    },
  },
  [APICategory.ticketing]: {
    Project: {
      deprecationStage: DeprecationStage.DEPRECATED,
      deprecationMessage: (
        <>
          Merge has deprecated this model and will no longer support it via API after{" "}
          <b>April 30th, 2023</b>. We strongly recommend using the <code>Collection</code> common
          model as it has the same capabilities as <code>Projects</code> and is more comprehensive.
        </>
      ),
    },
  },
  [APICategory.accounting]: {
    CreditNoteLineItem: {
      fields: {
        tracking_category: {
          deprecationStage: DeprecationStage.SUNSET,
        },
      },
    },
    VendorCreditLine: {
      fields: {
        tracking_category: {
          deprecationStage: DeprecationStage.SUNSET,
        },
      },
    },
    PurchaseOrderLineItem: {
      fields: {
        tracking_category: {
          deprecationStage: DeprecationStage.SUNSET,
        },
      },
    },
    JournalLine: {
      fields: {
        tracking_category: {
          deprecationStage: DeprecationStage.SUNSET,
        },
      },
    },
    InvoiceLineItem: {
      fields: {
        tracking_category: {
          deprecationStage: DeprecationStage.SUNSET,
        },
      },
    },
    ExpenseLine: {
      fields: {
        tracking_category: {
          deprecationStage: DeprecationStage.SUNSET,
        },
      },
    },
    AccountingTransactionLineItem: {
      fields: {
        tracking_category: {
          deprecationStage: DeprecationStage.SUNSET,
        },
      },
    },
  },
  [APICategory.ats]: {},
  [APICategory.crm]: {},
  [APICategory.filestorage]: {},
  [APICategory.mktg]: {},
};

export const ADDITIONAL_ENDPOINT_INFO_MAP: Record<string, AdditionalEndpointInfo> = {
  "timesheet-entries": {
    isBeta: true,
    category: APICategory.hris,
  },
  "accounting-periods": {
    isBeta: true,
    category: APICategory.accounting,
  },
  "job-postings": {
    isBeta: true,
    category: APICategory.ats,
  },
  roles: {
    isBeta: true,
    category: APICategory.ticketing,
  },
  projects: {
    category: APICategory.ticketing,
    deprecationStage: DeprecationStage.SUNSET,
    deprecationTooltip: "Deprecated in favor of the Collection object.",
    headerWarningMessage: (
      <>
        Merge will no longer support the Project common model after April 30th, 2023. This means we
        will not add any improvements or support any bug fixes related to Projects. We strongly
        recommend using Collections as it has the same capabilities as Projects and is more
        comprehensive. If you have any questions, please contact us.
      </>
    ),
  },
  teams: {
    category: APICategory.hris,
    deprecationStage: DeprecationStage.SUNSET,
    deprecationTooltip: "Deprecated in favor of the Group object.",
    headerWarningMessage: (
      <>
        Merge will no longer support the <code>Team</code> common model after{" "}
        <b>April 20th, 2023</b>. This means we will not add any improvements or support any bug
        fixes related to Teams. We strongly recommend using the <code>Group</code> common model as
        it has the same capabilities as Teams and is more comprehensive. If you have any questions,
        please contact us.
      </>
    ),
  },
};

/// /////////////////////////////

/// /////////////////////////////
/// /             Logs       ////
/// /////////////////////////////

export const LOG_TYPES = {
  API_REQUEST_LOG_ENTRY_TYPE: "APIRequestLogEntry",
};

export const LOG_DIRECTIONS = {
  LOG_INBOUND: "INBOUND",
  LOG_OUTBOUND: "OUTBOUND",
};

export const REDACTED_VALUE_TEXT = "<redacted>";
export const QUICKBOOKS_DESKTOP_INTEGRATION_ID = "4f340c8e-10d1-4989-8c36-579c6e0f0b3c";

/// /////////////////////////////

/// /////////////////////////////
/// /       Issues           ////
/// /////////////////////////////

export const ISSUE_STATUSES = {
  ISSUE_STATUS_ONGOING: "ONGOING",
  ISSUE_STATUS_RESOLVED: "RESOLVED",
};

export enum IssueDescription {
  INVALID_LOGIN_CREDENTIALS = "Invalid Login Credentials",
  MISSING_PERMISSION = "Missing Permission",
  BAD_API_KEY = "Bad API Key",
  HIT_RATE_LIMIT = "Hit Rate Limit",
  EXPIRED_MFA_AUTHENTICATION = "Expired Multi-factor Authentication",
  SFTP_MISSING_REQUIRED_FIELDS = "Required Fields Missing",
  SFTP_UNRECOGNIZED_FILE_NAME = "Unrecognized File Name",
  SFTP_UNSUPPORTED_FILE_TYPE = "Unsupported File Type",
  SFTP_INCORRECTLY_FORMATTED_ROW = "Incorrectly Formatted Row",
}

/// /////////////////////////////

/// /////////////////////////////
/// /     Date Options       ////
/// /////////////////////////////

export enum DATE_PICKER_OPTIONS {
  ALL_TIME = "All time",
  PAST_24_HOURS = "Past 24 hours",
  PAST_WEEK = "Past week",
  PAST_MONTH = "Past month",
  PAST_YEAR = "Past year",
  CUSTOM = "Custom...",
}

// Options to use for date formatting
export const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short",
  hour12: true,
};

export enum FieldMappingSource {
  LINKED_ACCOUNT = "LINKED_ACCOUNT",
  ORGANIZATION = "ORGANIZATION",
}

export const INTEGRATION_WIDE_FIELD_MAPPING_ENABLED_FLAG =
  "is_integration_wide_field_mappings_enabled";
export const LinkedAccountStatuses = {
  ALL: "ALL",
  COMPLETE: "COMPLETE",
  INCOMPLETE: "INCOMPLETE",
  RELINK_NEEDED: "RELINK_NEEDED",
  IDLE: "IDLE",
};

export const ScopeStatuses = {
  READ: "Read",
  READ_WRITE: "R + W",
  WRITE: "Write",
  DISABLED: "Disabled",
  RESET: "Reset",
};

export const paymentOptions = [
  {
    value: "BANK_ACCOUNT",
    text: "ACH/Bank account",
    disabled: false,
  },
  {
    value: "CREDIT_CARD",
    text: "Credit card",
    disabled: false,
  },
];

export const ISSUE_SYSTEM_V2 = "ISSUE_SYSTEM_V2";

export const MENDABLE_ANON_KEY = "d05418d3-29b3-4942-b03b-51f05446823e";

export enum ScopeActions {
  ENABLED_ACTION_READ = "ENABLED_ACTION_READ",
  ENABLED_ACTION_WRITE = "ENABLED_ACTION_WRITE",
}

export const CATEGORY_RECOMMENDED_MODELS = {
  crm: [],
  accounting: ["account", "companyinfo", "contact", "trackingcategory", "item"],
  ats: ["job", "candidate", "application"],
  hris: ["employee"],
  ticketing: ["user", "ticket"],
  filestorage: ["folder", "file"],
  mktg: [],
};
