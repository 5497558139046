import React, { useState } from "react";
import { Link, Spinner, Text } from "@merge-api/merge-javascript-shared";
import { APICategory, LinkedAccount } from "../../../../../models/Entities";
import useLoadLinkedAccountConditionData from "./hooks/useLoadLinkedAccountConditionData";
import LinkedAccountConditionsList from "./components/LinkedAccountConditionsList";
import useManageLinkedAccountConditions from "./hooks/useManageLinkedAccountConditions";
import LinkedAccountConditionHeader from "./components/LinkedAccountConditionHeader";
import LinkedAccountConditionPublishChangesDialog from "./components/LinkedAccountConditionPublishChangesDialog";
import LinkedAccountConditionConfirmDiscardChangesDialog from "./components/LinkedAccountConditionConfirmDiscardChangesDialog";
import LinkedAccountFileStorageFiltersTable from "./components/LinkedAccountFileStorageFiltersTable";
import useLoadLinkedAccountFileStorageFilters from "./hooks/useLoadLinkedAccountFileStorageFilters";
import useAppContext from "../../../../context/useAppContext";
import { BillingPlanTier } from "../../../../settings/billing/BillingModels";
import { MergeFlagFeature, useMergeFlag } from "../../../../shared-components/hooks/useMergeFlag";

type LinkedAccountSelectiveSyncPageProps = {
  linkedAccount: LinkedAccount;
};

const LinkedAccountSelectiveSyncPage = ({ linkedAccount }: LinkedAccountSelectiveSyncPageProps) => {
  const { user } = useAppContext();
  // state
  const [publishChangesDialogOpen, setPublishChangesDialogOpen] = useState(false);

  // hooks
  const { loading, remoteLinkedAccountConditions, linkedAccountConditionMeta, refetch } =
    useLoadLinkedAccountConditionData({ linkedAccount });
  const {
    linkedAccountConditions,
    linkedAccountConditionErrors,
    onLinkedAccountConditionChange,
    onLinkedAccountConditionDelete,
    onLinkedAccountConditionAdd,
    publishChanges,
    changes,
    publishing,
    hasChanges,
    validateChanges,
  } = useManageLinkedAccountConditions({
    remoteLinkedAccountConditions,
    linkedAccountConditionMeta,
    linkedAccountId: linkedAccount.id,
    refetch: () => {
      refetch();
      setPublishChangesDialogOpen(false);
    },
  });
  const { linkedAccountFileStorageFilters } = useLoadLinkedAccountFileStorageFilters({
    linkedAccount,
  });

  // event handlers
  const onPublishChangesClick = () => {
    const hasErrors = validateChanges();

    if (hasErrors) return;
    setPublishChangesDialogOpen(true);
  };

  // derived state
  const showFileStorageSelectiveSync =
    linkedAccount.category === APICategory.filestorage &&
    user.is_file_storage_selective_sync_enabled;

  // the only case where we dont want to show this subtitle - is if this account is a file storage account, it is in the flag for file_storage_selective_sync
  // and it has no file storage filters set up -> so if any of these conditions are false we will show the subtitle
  const showSubtitle =
    linkedAccount.category !== APICategory.filestorage ||
    !user.is_file_storage_selective_sync_enabled ||
    (linkedAccountFileStorageFilters && linkedAccountFileStorageFilters.length > 0);

  let { enabled: defaultDataEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_ENABLE_DEFAULT_DATA,
    organizationId: user.organization.id,
  });

  if (defaultDataEnabled == undefined) defaultDataEnabled = false;

  const showGatedSelectiveSync =
    defaultDataEnabled &&
    user.organization.organization_billing_plan?.billing_plan.plan_tier !==
      BillingPlanTier.BILLING_PLAN_TIER_ENTERPRISE &&
    user.organization.organization_billing_plan?.billing_plan.plan_tier !==
      BillingPlanTier.BILLING_PLAN_TIER_PROFESSIONAL;

  const hasDefaultFiltersIfGatedSelectiveSync =
    showGatedSelectiveSync &&
    remoteLinkedAccountConditions &&
    remoteLinkedAccountConditions.length > 0;

  return (
    <>
      <div>
        <LinkedAccountConditionHeader
          hasChanges={hasChanges}
          onPublishChangesClick={onPublishChangesClick}
          category={linkedAccount.category}
          linkedAccount={linkedAccount}
          showSubtitle={showSubtitle}
        />
        <div className="mt-8 pb-6">
          {loading || !linkedAccountConditionMeta ? (
            <div className="flex justify-center">
              <Spinner />
            </div>
          ) : showGatedSelectiveSync && !hasDefaultFiltersIfGatedSelectiveSync ? (
            <div className="mt-14 flex flex-col items-center">
              <Text variant="h4" className="text-gray-40">
                No filters available
              </Text>
              <Text variant="sm" className="text-gray-40 font-semibold text-center">
                <Link href="/billing" className="text-blue">
                  Upgrade your plan{" "}
                </Link>
                to add filters
              </Text>
            </div>
          ) : !showFileStorageSelectiveSync && linkedAccountConditionMeta ? (
            <LinkedAccountConditionsList
              linkedAccount={linkedAccount}
              linkedAccountConditions={linkedAccountConditions}
              linkedAccountConditionErrors={linkedAccountConditionErrors}
              linkedAccountConditionMeta={linkedAccountConditionMeta}
              onLinkedAccountConditionChange={onLinkedAccountConditionChange}
              onLinkedAccountConditionDelete={onLinkedAccountConditionDelete}
              onLinkedAccountConditionAdd={onLinkedAccountConditionAdd}
              showGatedSelectiveSync={showGatedSelectiveSync}
            />
          ) : linkedAccountFileStorageFilters && linkedAccountFileStorageFilters?.length > 0 ? (
            <LinkedAccountFileStorageFiltersTable
              linkedAccountFileStorageFilters={linkedAccountFileStorageFilters}
            />
          ) : (
            <div className=" mt-14 flex justify-center">
              <Text variant="h4" className="text-gray-40">
                No filters have been set for this Linked Account
              </Text>
            </div>
          )}
        </div>
      </div>

      <LinkedAccountConditionConfirmDiscardChangesDialog hasChanges={hasChanges} />

      <LinkedAccountConditionPublishChangesDialog
        organizationName={linkedAccount.end_user.organization_name}
        changes={changes}
        open={publishChangesDialogOpen}
        onClose={() => setPublishChangesDialogOpen(false)}
        publishChanges={publishChanges}
        publishing={publishing}
      />
    </>
  );
};

export default LinkedAccountSelectiveSyncPage;
