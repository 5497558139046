import React from "react";
import PersonalInformationCard from "./cards/PersonalInformationCard";
import AvatarCard from "./cards/AvatarCard";

function Profile() {
  return (
    <div className="flex flex-row w-full">
      <div className="mr-6">
        <AvatarCard />
      </div>
      <div className="w-full">
        <PersonalInformationCard />
      </div>
    </div>
  );
}

export default Profile;
