import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, Route, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { ISSUES_PATH, getLinkedAccountIssuesPathForId } from "../../../../router/RouterUtils";
import useAppContext from "../../../context/useAppContext";
import PortalPageHeaderWrapper from "../../../portal/PortalPageHeaderWrapper";
import ClickableContainer from "../../../shared-components/ClickableContainer";
import { showErrorToast, showSuccessToast } from "../../../shared-components/Toasts";
import {
  IntegrationIssue,
  IntegrationIssueActionLogEntryAction,
  IntegrationIssueActor,
  IntegrationIssueStatus,
} from "../../IntegrationsManagementEntities";

import {
  fetchIntegrationIssue,
  updateIntegrationIssueStatus,
  fetchLogEntryByID,
} from "../../utils/IntegrationsManagementAPIClient";
import { getIssueCreatedAt, isIssueMuted } from "../../utils/IntegrationsManagementUtils";
import { IntegrationIssueCommentModal } from "../IntegrationIssueModals";
import IndividualIssueActivityV2 from "./IndividualIssueActivityV2";
import IndividualIssueMuteButton from "./IntegrationsManagementIndividualIssueMuteButton";
import { DATE_FORMAT, IssueDescription } from "../../../../constants";
import APIRequestLogEntrySidePanel from "../../logs/side-panel/APIRequestLogSidePanel";
import { ArrowLeft } from "lucide-react";
import IntegrationIssueDetailedErrorContainer from "../../linked-accounts/detail-page/overview/IntegrationIssueDetailedErrorContainer";
import ImpactedModelsRow from "../../linked-accounts/detail-page/overview/ImpactedModelsRow";
import { ErrorStripe } from "../../linked-accounts/detail-page/overview/IntegrationIssueWidget";
import { getImpactedModels } from "../../utils/IntegrationsManagementUtils";
import { Button as MergeButton, Badge, ButtonVariant } from "@merge-api/merge-javascript-shared";
import IDsCard from "../../linked-accounts/detail-page/overview/IDsCard";

const IssueStatusContainer = styled.div`
  border-radius: 6px;
  border: 0.5px solid var(--lm-gray-20, #dce2ea);
  background: #f5f7f9;
`;

const Section = styled.div`
  min-width: 0;
`;

const DateText = styled.div`
  font-size: 11px;
`;

const RemediationContainer = styled.div`
  box-shadow:
    0px 3px 12px -3px rgba(0, 0, 0, 0.12),
    0px 0px 0px 0.5px rgba(220, 226, 234, 0.2);
  background: #fff;
  border-radius: 0 0 8px 8px;
`;

export const getResolvedBy = (integrationIssue: IntegrationIssue) => {
  if (integrationIssue?.metadata) {
    for (let i = integrationIssue.metadata.length - 1; i >= 0; i--) {
      const action = integrationIssue.metadata[i];
      if (action.action == IntegrationIssueActionLogEntryAction.RESOLVE) {
        switch (action.actor_type) {
          case IntegrationIssueActor.AUTO_ACTOR:
            return "Auto-resolved";
          case IntegrationIssueActor.USER_ACTOR:
            return `Resolved by ${action.user_name || action.user_email}`;
          default:
            return "Resolved";
        }
      }
    }
  }
  return "Resolved";
};
const IntegrationsManagementIndividualIssuePageV2 = () => {
  const [integrationIssue, setIntegrationIssue] = useState<undefined | IntegrationIssue>();
  const [isCommentModalShown, setIsCommentModalShown] = useState<boolean>(false);
  const history = useHistory<any>();
  const [resolveLoading, setResolveLoading] = useState<boolean>(false);
  const isFromLinkedAccountPage = history?.location?.state?.from == "linked_account_page";
  const { integrationIssueID } = useParams<{
    integrationIssueID: string;
  }>();
  const isResolved = integrationIssue?.status === IntegrationIssueStatus.RESOLVED;
  const isMuted = integrationIssue ? isIssueMuted(integrationIssue) : false;
  const isMissingPermissionIssue =
    integrationIssue?.error_description == IssueDescription.MISSING_PERMISSION;
  const isWarningIssue =
    integrationIssue?.error_description == IssueDescription.MISSING_PERMISSION ||
    integrationIssue?.error_description == IssueDescription.SFTP_MISSING_REQUIRED_FIELDS ||
    integrationIssue?.error_description == IssueDescription.SFTP_INCORRECTLY_FORMATTED_ROW;

  const impactedModels = integrationIssue?.metadata
    ? getImpactedModels(integrationIssue.metadata)
    : {};
  const hasImpactedModels = Object.keys(impactedModels).length > 0;
  useEffect(() => {
    fetchIntegrationIssue({
      integrationIssueID,
      onSuccess: (result: IntegrationIssue) => {
        setIntegrationIssue(result);
      },
    });
  }, [integrationIssueID]);

  const updateStatus = (status: IntegrationIssueStatus) => {
    setResolveLoading(true);
    updateIntegrationIssueStatus({
      integrationIssueID,
      status,
      onSuccess: (data: IntegrationIssue) => {
        setIntegrationIssue(data);
        setResolveLoading(false);
        showSuccessToast("Issue marked as " + status.toLowerCase() + ".");
      },
      onError: () => {
        showErrorToast("Issue resolution failing.");
      },
    });
  };

  function clickResolveButton() {
    if (isResolved) {
      updateStatus(IntegrationIssueStatus.ONGOING);
    } else {
      updateStatus(IntegrationIssueStatus.RESOLVED);
    }
  }

  if (!integrationIssue) return <></>;

  const issueCreatedDate = getIssueCreatedAt(integrationIssue);

  const dateCreatedString = issueCreatedDate
    ? new Date(issueCreatedDate).toLocaleDateString(undefined, DATE_FORMAT)
    : "-";

  const issueComponent = (
    <IssueStatusContainer className="d-flex flex-column pt-1.5 pb-2 pl-2 pr-2">
      <div className="font-semibold text-sm">Issue created</div>
      <DateText className="font-mono mb-2">{dateCreatedString}</DateText>
      <Section>
        <MergeButton
          variant={ButtonVariant.TertiaryWhite}
          size="sm"
          onClick={() => setIsCommentModalShown(true)}
          fullWidth
        >
          <span className="fe fe-message-square mr-2" />
          {integrationIssue.comment_count} comments
        </MergeButton>
      </Section>
    </IssueStatusContainer>
  );

  return (
    <PortalPageHeaderWrapper
      title={
        <div className="d-flex align-items-center">
          <span>{integrationIssue.error_description}</span>
          {isMuted && <span className="fe fe-volume-x ml-3 mr-2" />}
          <Badge
            color={isResolved ? "teal" : isWarningIssue ? "amber" : "red"}
            size="lg"
            className="ml-3"
          >
            {!isResolved ? "Ongoing" : "Resolved"}
          </Badge>
        </div>
      }
      isMaxWidthEnabled
      subtitle={
        <div className="align-items-center">
          <ClickableContainer>
            <Link
              to={
                isFromLinkedAccountPage
                  ? getLinkedAccountIssuesPathForId(integrationIssue.linked_account.id)
                  : ISSUES_PATH
              }
              className="font-semibold text-blue-40"
            >
              <div className="d-flex items-center">
                <ArrowLeft size={14} className="mr-1" />
                {isFromLinkedAccountPage ? "Back to linked account" : "Back to issues"}
              </div>
            </Link>
          </ClickableContainer>
          {isResolved != undefined && (
            <Button className="float-right" variant="white" size="sm" onClick={clickResolveButton}>
              {resolveLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  />
                  {isResolved ? "Reopening" : "Resolving"}
                </>
              ) : (
                <>
                  <span className="fe fe-check mr-2" />
                  {isResolved ? "Resolved" : "Resolve Issue"}
                </>
              )}
            </Button>
          )}
          <IndividualIssueMuteButton
            integrationIssue={integrationIssue}
            onIssueUpdate={setIntegrationIssue}
          />
        </div>
      }
    >
      <>
        <Row>
          <Col xs="12" md="9" lg="9" xl="9">
            <>
              <ErrorStripe isWarningIssue={isWarningIssue} />
              <RemediationContainer
                className={`${
                  isMissingPermissionIssue ? "pt-3 pl-4 pb-4 pr-4" : "pt-2 pl-4 pb-4 pr-4 "
                }`}
              >
                {isMissingPermissionIssue && integrationIssue.metadata && hasImpactedModels && (
                  <ImpactedModelsRow impactedModels={impactedModels} isOverviewPage={false} />
                )}
                <div
                  className={`${
                    isMissingPermissionIssue && hasImpactedModels
                      ? "mt-3 mb-2 text-sm font-semibold"
                      : "mb-3 text-base font-semibold "
                  }`}
                >
                  Recommended remediation to share with your customer
                </div>
                <IntegrationIssueDetailedErrorContainer
                  integrationIssue={integrationIssue}
                  showPreview={false}
                  issueMetadata={integrationIssue.metadata}
                  isMissingPermissionIssue={
                    integrationIssue.error_description == "Missing Permission"
                  }
                />
              </RemediationContainer>
              <div className="mt-6">
                <IndividualIssueActivityV2 integrationIssue={integrationIssue} />
              </div>
            </>
          </Col>
          <Col xs="12" md="3" lg="3" xl="3">
            <IDsCard
              linkedAccount={integrationIssue.linked_account}
              integrationIssueComponent={issueComponent}
            />
          </Col>
        </Row>
        <IntegrationIssueCommentModal
          integrationIssue={integrationIssue}
          isShown={isCommentModalShown}
          setIsShown={setIsCommentModalShown}
        />
        <Route
          exact
          path={`${ISSUES_PATH}/:integrationIssueID/log-entry/:logID`}
          render={() => <APIRequestLogEntrySidePanel fetchAPIRequestLog={fetchLogEntryByID} />}
        />
      </>
    </PortalPageHeaderWrapper>
  );
};

export default IntegrationsManagementIndividualIssuePageV2;
