import React from "react";
import styled from "styled-components";
import size from "lodash/size";
import { AlertTriangle, X } from "lucide-react";
import IntegrationIssueLogsRow from "./IntegrationIssueLogsRow";
import { LinkedAccount, Log } from "../../../../../models/Entities";
import IntegrationIssueDetailedErrorContainer from "./IntegrationIssueDetailedErrorContainer";
import { spectrum } from "../../../../../styles/theme";
import { IntegrationIssue } from "../../../IntegrationsManagementEntities";
import ImpactedModelsRow from "./ImpactedModelsRow";
import { getImpactedModels } from "../../../utils/IntegrationsManagementUtils";
import { dismissIntegrationIssue } from "../../../utils/IntegrationsManagementAPIClient";
import { showErrorToast, showSuccessToast } from "../../../../shared-components/Toasts";
import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import { useRequest } from "../../../../shared-components/hooks/useRequest";
import { IssueDescription } from "../../../../../constants";

const IntegrationsIssuesCard = styled.div`
  padding: 12px 16px 16px 16px;
  border-radius: 0 0 8px 8px;
  background: #ffffff;
  box-shadow:
    0px 3px 12px -3px rgba(0, 0, 0, 0.12),
    0px 0px 0px 0.5px rgba(220, 226, 234, 0.2);
`;

type ErrorTextProps = {
  isWarningIssue: boolean;
};

const ErrorTitleText = styled.div<ErrorTextProps>`
  font-weight: 600;
  font-size: 16px;
  margin-left: 8px;
  color: ${(props) => (props.isWarningIssue ? spectrum.amber50 : spectrum.red50)};
`;

export const ErrorStripe = styled.div<ErrorTextProps>`
  height: 8px;
  border-radius: 8px 8px 0px 0px;
  background: ${(props) => (props.isWarningIssue ? spectrum.amber50 : spectrum.red50)};
`;

type IntegrationIssuesWidgetProps = {
  integrationIssue: IntegrationIssue;
  linkedAccount: LinkedAccount | null;
  showPreview: boolean;
  refetchIntegrationIssues: () => void;
};
const IntegrationIssueWidget = ({
  integrationIssue,
  linkedAccount,
  showPreview,
  refetchIntegrationIssues,
}: IntegrationIssuesWidgetProps) => {
  const { data: issueLogs } = useRequest<{ recent_logs: Log[] }>({
    path: `integrations/issues/${integrationIssue.id}/logs`,
    method: HTTPMethod.GET,
    skip: !integrationIssue,
  });

  const impactedModels = integrationIssue?.metadata
    ? getImpactedModels(integrationIssue.metadata)
    : {};
  const isMissingPermissionsIssue = integrationIssue.error_description == "Missing Permission";
  const isWarningIssue =
    integrationIssue.error_description == IssueDescription.MISSING_PERMISSION ||
    integrationIssue.error_description == IssueDescription.SFTP_MISSING_REQUIRED_FIELDS ||
    integrationIssue.error_description == IssueDescription.SFTP_INCORRECTLY_FORMATTED_ROW;
  const hasImpactedModels = Object.keys(impactedModels).length > 0;
  return (
    <>
      <ErrorStripe isWarningIssue={isWarningIssue} />
      <IntegrationsIssuesCard className="d-flex flex-column">
        <div className="d-flex">
          <div className="d-flex align-items-center">
            <AlertTriangle
              size={16}
              color={isWarningIssue ? spectrum.amber50 : spectrum.red50}
              className={isWarningIssue ? "merge-text-warn" : "merge-text-danger"}
            />
            <ErrorTitleText isWarningIssue={isWarningIssue}>
              {integrationIssue.error_description}
            </ErrorTitleText>
          </div>
          <div className="clickable-icon ml-auto">
            <X
              size={12}
              color={`${spectrum.gray50}`}
              onClick={() => {
                dismissIntegrationIssue({
                  integrationIssueId: integrationIssue.id,
                  onSuccess: () => {
                    showSuccessToast("Successfully Dismissed Issue!");
                    refetchIntegrationIssues();
                  },
                  onFailure: () => {
                    showErrorToast("Unable to Dismiss Issue");
                  },
                });
              }}
            />
          </div>
        </div>
        {isMissingPermissionsIssue && integrationIssue.metadata && hasImpactedModels && (
          <ImpactedModelsRow impactedModels={impactedModels} isOverviewPage />
        )}
        <div className="mt-3 medium text-sm">
          Recommended remediation to share with your customer
        </div>
        {integrationIssue && (
          <IntegrationIssueDetailedErrorContainer
            issueMetadata={integrationIssue.metadata}
            integrationIssue={integrationIssue}
            showPreview={showPreview}
            isMissingPermissionIssue={isMissingPermissionsIssue}
          />
        )}
        {size(issueLogs?.recent_logs) > 0 && showPreview && linkedAccount && (
          <IntegrationIssueLogsRow
            logs={issueLogs?.recent_logs ?? []}
            linkedAccountId={linkedAccount.id}
          />
        )}
      </IntegrationsIssuesCard>
    </>
  );
};
export default IntegrationIssueWidget;
