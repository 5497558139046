import React from "react";
import clsx from "clsx";
import { ConditionOperator, ConditionPreset } from "../../../../../models/Entities";
import { Text } from "@merge-api/merge-javascript-shared";
import LinkConditionPreset from "./LinkConditionPreset";
import { format, subYears, subDays, subMonths } from "date-fns";
import { PeriodValues } from "../../constants";

type LinkCardProps = {
  className?: string;
  conditionPresets?: Partial<ConditionPreset>[];
  showLinkCardData: boolean;
};

const LinkCard = ({ className, conditionPresets, showLinkCardData }: LinkCardProps) => {
  if (!conditionPresets?.length || showLinkCardData === false) {
    return (
      <Text>
        Select an integration to view what filters will appear in Merge Link for your users.
        Coverage is limited and varies by integration.
      </Text>
    );
  }

  function convertToAbsoluteDate(value: string, period: string): string {
    const now = new Date();

    const delta = parseInt(value, 10);
    let absoluteDate = now;

    switch (period) {
      case PeriodValues.YEARS:
        absoluteDate = subYears(now, delta);
        break;
      case PeriodValues.DAYS:
        absoluteDate = subDays(now, delta);
        break;
      case PeriodValues.MONTHS:
        absoluteDate = subMonths(now, delta);
        break;
    }

    return format(absoluteDate, "yyyy-MM-dd");
  }

  function convertRelativeDatePreset(conditionPreset: ConditionPreset): void {
    conditionPreset.value = convertToAbsoluteDate(conditionPreset.value!, conditionPreset.period!);
    conditionPreset.period = null;
    conditionPreset.operator = ConditionOperator.GREATER_THAN_OR_EQUAL_TO;
  }

  return (
    <div
      className={clsx(
        "flex flex-col rounded-lg border-gray-0 border-[1px] p-5 select-none h-[70vh] max-h-[720px] justify-between", // height is kinda hacky, but works
        className,
      )}
    >
      {/* header */}
      <div className="flex flex-col flex-shrink-0">
        <Text variant="h3" className="mb-2">
          Filter your data
        </Text>
        <Text variant="sm" className="text-gray-60">
          Only share data where...
        </Text>
      </div>
      {/* condition presets */}
      <div className="mt-6 flex flex-col flex-grow overflow-y-auto -mx-5 px-5">
        {conditionPresets
          .filter(({ is_end_user_configured, value }) => is_end_user_configured || value)
          .map((conditionPreset, index) => {
            if (
              conditionPreset.value !== null &&
              conditionPreset.period !== null &&
              conditionPreset.operator === ConditionOperator.WITHIN
            ) {
              convertRelativeDatePreset(conditionPreset as ConditionPreset);
            }

            return (
              <React.Fragment key={index}>
                <LinkConditionPreset
                  key={index}
                  conditionPreset={conditionPreset}
                  className="mb-1.5"
                />
                {index < conditionPresets.length - 1 && (
                  <Text
                    className="text-gray-600 font-semibold tracking-wide pb-2"
                    variant="caption"
                  >
                    AND
                  </Text>
                )}
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default LinkCard;
