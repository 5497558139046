import React, { useState } from "react";
import { Card, Text, TextField, Button } from "@merge-api/merge-javascript-shared";
import useAppContext from "../../../context/useAppContext";
import Dropzone from "../../../shared-components/Dropzone";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import { showErrorToast, showSuccessToast } from "../../../shared-components/Toasts";

const PersonalInformationCard = () => {
  // STATE
  const { setUser, user } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [avatar, setAvatar] = useState<null | Blob>();
  const [name, setName] = useState<string>(user.name);
  const [error, setError] = useState<boolean>(false);

  // HANDLERS
  const handleName = (newName: string) => {
    if (newName.length < 1 || newName.length > 100) {
      setError(true);
    } else {
      setError(false);
    }
    setName(newName);
  };

  const handleAvatarUpload = (files: Array<File>) => {
    const avatarFile = files[0];
    const blob = new Blob([avatarFile], { type: avatarFile.type });
    setAvatar(blob);
  };

  const onSubmit = (data: { name: string; email: string }) => {
    setIsLoading(true);
    const formData = {
      avatar,
      name: data.name,
    };

    fetchWithAuth({
      path: "/users/me",
      method: HTTPMethod.PATCH,
      body: formData,
      onResponse: (data) => {
        showSuccessToast("Successfully updated your profile!");
        setUser(data.user);
        setIsLoading(false);
      },
      onError: () => {
        showErrorToast("Failed to update your profile.");
        setIsLoading(false);
      },
    });
  };

  return (
    <Card variant="shadow">
      <div className="w-full flex flex-col">
        {/* header */}
        <div className="px-6 py-5 border-b-solid border-b-gray-20 border-b-[0.5px]">
          <Text variant="h5">Personal information</Text>
        </div>

        {/* body */}
        <div className="flex flex-col px-6 py-5 justify-between gap-y-5">
          {/* name */}
          <div className="flex flex-col gap-2">
            <Text variant="h6">Full name</Text>
            <TextField
              variant="shadowed"
              defaultValue={name}
              onChange={(e) => handleName(e.target.value)}
              error={error}
              errorText="Please enter a name between 1 - 100 characters"
            />
          </div>

          {/* email */}
          <div className="flex flex-col gap-2">
            <Text variant="h6">Email address</Text>
            <Text className="bg-gray-10 text-black rounded-md h-10 items-center flex pl-4 pr-4">
              {user.email}
            </Text>
          </div>

          {/* avatar */}
          <div className="flex flex-col gap-2">
            <Text variant="h6">Avatar</Text>
            <Dropzone upload={handleAvatarUpload} />
          </div>

          {/* save */}
          <Button
            loading={isLoading}
            disabled={error}
            onClick={() => onSubmit({ name, email: user.email })}
          >
            Save changes
          </Button>
        </div>
      </div>
    </Card>
  );
};
export default PersonalInformationCard;
