import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Select,
  APICategory,
  displayNameForAPICategory,
  Button,
  ButtonVariant,
  Badge,
  Text,
  Link,
  Tooltip,
} from "@merge-api/merge-javascript-shared";
import { MonitorUp } from "lucide-react";
import INTEGRATION_CATEGORY_LIST from "../../../models/Helpers";
import { SectionHeaderWrapper } from "../../shared-components/MergeLayouts";
import { useHistory, useParams } from "react-router-dom";
import ConfigurationSelectiveSyncCard from "./ConfigurationSelectiveSyncCard";
import ConfigurationSelectiveSyncSettingsPage from "./ConfigurationSelectiveSyncSettingsPage";
import useProductRestrictions from "../../shared-components/hooks/useProductRestrictions";
import { OrganizationCustomization } from "../link/context/CustomizationContext";

type ConditionPresetHeaderProps = {
  hasChanges: boolean;
  previewLinkEnabled: boolean;
  onPublishChangesClick: () => void;
  setConditionPresetPreviewLinkDialogOpen: Dispatch<SetStateAction<boolean>>;
  organizationCustomization: OrganizationCustomization | null;
  setOrganizationCustomization: (organizationCustomization: OrganizationCustomization) => void;
};

const ConditionPresetHeader = ({
  hasChanges,
  previewLinkEnabled,
  onPublishChangesClick,
  setConditionPresetPreviewLinkDialogOpen,
  organizationCustomization,
  setOrganizationCustomization,
}: ConditionPresetHeaderProps) => {
  // hooks
  const history = useHistory();
  const { category } = useParams<{ category: APICategory }>();
  const { productRestrictions } = useProductRestrictions();

  // state
  const [tempCategory, setTempCategory] = useState<APICategory>();

  // event handlers
  const onCategoryChange = (_: any, category: APICategory | null) => {
    // set temp category to temporarily decouple Select from the category in url.
    // this is necessary b/c select maintains an internal state and expects when you
    // change, if controlled, that you actually update the external state, which
    // is not always true due to our usage of the react router Prompt
    setTempCategory(category!);
    history.push(`/configuration/selective-sync/${category!}`);
    setTimeout(() => setTempCategory(undefined), 0);
  };

  return (
    <>
      <SectionHeaderWrapper
        title={
          <div className="flex flex-row gap-3 items-center">
            Selective Sync
            <Badge color="blue">Beta</Badge>
          </div>
        }
        subtitle={
          <Text>
            Create preset filters that automatically apply to new Linked Accounts. Integration
            support is limited.{" "}
            <Link href="https://help.merge.dev/en/articles/9113654-selective-sync">
              Learn more.{" "}
            </Link>
          </Text>
        }
      />
      {/* toolbar */}
      {!productRestrictions?.customizable_link_enabled ? (
        <ConfigurationSelectiveSyncSettingsPage
          setConditionPresetPreviewLinkDialogOpen={setConditionPresetPreviewLinkDialogOpen}
        />
      ) : (
        <ConfigurationSelectiveSyncCard
          previewLinkEnabled={previewLinkEnabled}
          setConditionPresetPreviewLinkDialogOpen={setConditionPresetPreviewLinkDialogOpen}
          organizationCustomization={organizationCustomization}
          setOrganizationCustomization={setOrganizationCustomization}
        ></ConfigurationSelectiveSyncCard>
      )}
      <div className="flex justify-between items-center mt-6 mb-8">
        <Select
          className="max-w-[192px] bg-white"
          options={INTEGRATION_CATEGORY_LIST}
          renderOption={(category: APICategory) => <>{displayNameForAPICategory(category)}</>}
          onChange={onCategoryChange}
          value={tempCategory || category}
          clearable={false}
        />
        <div className="flex flex-col lg:flex-row gap-3">
          <Tooltip title={!hasChanges && "No changes to publish"}>
            <Button
              size="sm"
              variant={ButtonVariant.PrimaryBlue}
              fullWidth
              leftIcon={<MonitorUp className="h-3 w-3" />}
              onClick={onPublishChangesClick}
              disabled={!hasChanges}
            >
              Publish changes
            </Button>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default ConditionPresetHeader;
