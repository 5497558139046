import React from "react";
import { useHistory } from "react-router-dom";
import { navigateToLinkedAccountFieldMappings } from "../../../../../../../router/RouterUtils";
import ConfigurationWidgetCard from "./ConfigurationWidgetCard";
import { Badge, Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";

type FieldMappingsWidgetCardProps = {
  linkedAccountFieldMappingsCount: number | undefined;
  orgWideFieldMappingsCount: number | undefined;
  linkedAccountId: string;
  endUserOrganizationName: string;
};

const FieldMappingsWidgetCard = ({
  linkedAccountFieldMappingsCount = 0,
  orgWideFieldMappingsCount = 0,
  linkedAccountId,
  endUserOrganizationName,
}: FieldMappingsWidgetCardProps) => {
  const history = useHistory();

  return (
    <ConfigurationWidgetCard
      includeCardDivider
      cardTitle={<Text variant="h6">Field Mapping</Text>}
      badgeChildren={
        <Button
          variant={ButtonVariant.TextBlue}
          onClick={() => navigateToLinkedAccountFieldMappings(history, linkedAccountId)}
          size="sm"
        >
          Edit
        </Button>
      }
      cardSubtitle={
        <div className="flex flex-col gap-2">
          {/* Linked account */}
          <div className="flex flex-row justify-between">
            <Text variant="sm" className="text-black truncate">
              {endUserOrganizationName}-specific
            </Text>
            <Badge color="gray">{linkedAccountFieldMappingsCount}</Badge>
          </div>

          {/* Org wide */}
          <div className="flex flex-row justify-between">
            <Text variant="sm" className="text-black">
              Organization-wide
            </Text>
            <Badge color="gray">{orgWideFieldMappingsCount}</Badge>
          </div>
        </div>
      }
    />
  );
};

export default FieldMappingsWidgetCard;
