import React from "react";
import { useHistory } from "react-router-dom";
import { navigateToLinkedAccountFieldMappings } from "../../../../../../../router/RouterUtils";
import ConfigurationWidgetCard from "./ConfigurationWidgetCard";
import { Button, ButtonVariant, Text, Badge } from "@merge-api/merge-javascript-shared";

type CommonModelOverridesWidgetCardProps = {
  linkedAccountCommonModelOverridesCount: number | undefined;
  orgWideCommonModelOverridesCount: number | undefined;
  linkedAccountId: string;
  endUserOrganizationName: string;
};

const CommonModelOverridesWidgetCard = ({
  linkedAccountCommonModelOverridesCount = 0,
  orgWideCommonModelOverridesCount = 0,
  linkedAccountId,
  endUserOrganizationName,
}: CommonModelOverridesWidgetCardProps) => {
  const history = useHistory();

  return (
    <ConfigurationWidgetCard
      includeCardDivider
      cardTitle={<Text variant="h6">Common Model overrides</Text>}
      badgeChildren={
        <Button
          variant={ButtonVariant.TextBlue}
          onClick={() => navigateToLinkedAccountFieldMappings(history, linkedAccountId)}
          size="sm"
        >
          Edit
        </Button>
      }
      cardSubtitle={
        <div className="flex flex-col gap-2">
          {/* Linked account */}
          <div className="flex flex-row justify-between">
            <Text variant="sm" className="text-black truncate">
              {endUserOrganizationName}-specific
            </Text>
            <Badge color="gray">{linkedAccountCommonModelOverridesCount}</Badge>
          </div>

          {/* Org wide */}
          <div className="flex flex-row justify-between">
            <Text variant="sm" className="text-black">
              Organization-wide
            </Text>
            <Badge color="gray">{orgWideCommonModelOverridesCount}</Badge>
          </div>
        </div>
      }
    />
  );
};

export default CommonModelOverridesWidgetCard;
