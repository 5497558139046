import React from "react";
import LinkedAccountSettingToggle, {
  LinkedAccountSettingToggleProps,
} from "../../LinkedAccountSettingToggle";
import isUndefined from "lodash/isUndefined";

interface ConfigurationWidgetCardProps {
  cardTitle: string | JSX.Element;
  cardSubtitle?: JSX.Element | string;
  badgeChildren?: string | number | JSX.Element;
  linkedAccountSettingToggleProps?: LinkedAccountSettingToggleProps;
  includeCardDivider?: boolean;
}

const ConfigurationWidgetCard = ({
  cardTitle,
  cardSubtitle,
  badgeChildren,
  linkedAccountSettingToggleProps,
}: ConfigurationWidgetCardProps) => {
  return (
    <div className="flex flex-col pl-3">
      <div className="flex flex-row items-center justify-between">
        {cardTitle}
        {!isUndefined(badgeChildren) && badgeChildren}
      </div>
      <div className="pr-3">
        {linkedAccountSettingToggleProps && (
          <div className="pr-4">
            <LinkedAccountSettingToggle {...linkedAccountSettingToggleProps} />
          </div>
        )}
        {cardSubtitle && (
          <div className="mt-1 text-gray-50 text-xs font-normal">{cardSubtitle}</div>
        )}
      </div>
    </div>
  );
};

export default ConfigurationWidgetCard;
