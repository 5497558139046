import React from "react";
import { Card, Text } from "@merge-api/merge-javascript-shared";
import useAppContext from "../../../context/useAppContext";
import { UserAvatar, UserAvatarSize } from "../../../shared-components/MergeAvatars";

const AvatarCard = () => {
  const { user } = useAppContext();

  return (
    <Card variant="shadow" className="h-60 w-60">
      <div className="flex flex-col items-center px-12 py-6">
        <UserAvatar user={user} size={UserAvatarSize.xxl} />
        <div className="mt-3 text-center">
          <Text variant="h5">{user.name}</Text>
          <Text className="text-gray-60">{user.organization ? user.organization.name : ""}</Text>
        </div>
      </div>
    </Card>
  );
};

export default AvatarCard;
